import {getField, updateField} from 'vuex-map-fields';
import router from './../router';

export const formModule = {
  state: () => ({
    name: null,
    email: null,
    phone: null,
    comments: null,
    sentStatus: 'wait',
    isFormBlocked: false,
    utmList: [
      {
        name: 'utm_campaign',
        value: null
      },
      {
        name: 'utm_medium',
        value: null
      },
      {
        name: 'utm_content',
        value: null
      },
      {
        name: 'utm_term',
        value: null
      },
      {
        name: 'gclid',
        value: null
      },
      {
        name: 'utm_source',
        value: null
      }
    ]
  }),
  mutations: {
    setStatus(state, status) {
      state.sentStatus = status;
      if (state.sentStatus === 'success') {
        router.push({ name: 'thank-you' });
        state.isFormBlocked = true;
      } else if (state.sentStatus === 'error') {
        window.scrollBy(0, -300);
      }
    },
    utmData(state) {
      const href = location.href;

      const getUTMData = (utmName, symbol) => {
        const regex = new RegExp(`${utmName}[^${symbol}]*`, "iu");
        return href.match(regex)[0].slice(utmName.length + 1);
      };

      state.utmList.map(utm => {
        if (href.includes(utm.name)) {
          if (utm.name === 'gclid') {
            utm.value = getUTMData(utm.name, '#');
          } else {
            utm.value = getUTMData(utm.name, '&');
          }
        }
      });
    },
    send(state, form) {
      state.isFormBlocked = true;

      const data = new FormData(form);
      const sendMail = async (data) => {
        return new Promise((resolve, reject) => {
          fetch('/sender.php', {
            method: 'POST',
            mode: 'same-origin',
            cache: 'no-cache',
            credentials: 'same-origin',
            redirect: 'error',
            referrerPolicy: 'no-referrer',
            body: data
          }).then(response => {
            if (response.status !== 200) {
              reject(response.body);
            } else {
              resolve();
            }
          }).catch(error => {
            reject(error);
          });
        });
      };

      const sendEmail = () => {

        sendMail(data)
          .then(() => {
            this.commit('setStatus', 'success');
            state.isFormBlocked = false;
          }).catch(() => {
          this.commit('setStatus', 'error');
        });
      };

      sendEmail();
    },
    updateField,
  },
  getters: {
    getField,
  },
};