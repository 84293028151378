<template>
  <section id="services" class="services smooth">
    <img class="figure_services parallax figure_white" src="./../assets/figure.svg" alt="">
    <div class="container reveal">
      <img class="dots header-dots" src="./../assets/dots.svg" alt="">
      <h1 class="title">Услуги и<br>Направления</h1>
      <div class="services__list">
        <ServiceCardsCol
            :expand="expand"
            :reduce="reduce"
            :mobileScreenHandler="mobileScreenHandler"
            :cards="cards.leftSide"
            className="left-col"
        />
        <ServiceCardsCol
            :expand="expand"
            :reduce="reduce"
            :mobileScreenHandler="mobileScreenHandler"
            :cards="cards.rightSide"
            className="right-col"
        />
      </div>
      <img class="dots footer-dots footer-dots_services" src="./../assets/dots.svg" alt="">
    </div>
  </section>
</template>

<script>
import ServiceCardsCol from "@/components/ServiceCardsCol";

export default {
  name: "Services",
  data() {
    return {
      cards: {
        leftSide: [
          {
            id: 1,
            title: 'Контекстная реклама',
            text: 'Привлечение целевой аудитории благодаря поисковой рекламе. Данный вид рекламы позволяет возвращать пользователей на сайт, увеличивать продажи и средний чек, а также приносит максимально живой и разогретый трафик',
            img: '/img/ai.svg'
          },
          {
            id: 2,
            title: 'Медийная реклама',
            text: 'Медийная реклама решает имиджевые задачи и работает как инструмент брендформанс-стратегии. Полностью погружаемся в проекты: планируем, запускаем, оптимизируем и анализируем кампании. Постоянно исследуем как различные инструменты, форматы и каналы влияют на бизнес-показатели клиентов, а система аналитики позволяет оценить вклад каждого медийного размещения в общую рекламную кампанию. Выстраиваем стратегию продвижения так, чтобы ускорить путь пользователя и улучшить бизнес-результаты клиента',
            img: '/img/media-ad.svg'
          },
          {
            id: 3,
            title: 'Таргетированная реклама',
            text: 'Помогаем решать задачи клиента через продвижение в соцсетях, используя стратегическое планирование и комплексный подход. Работаем с площадками VK, Facebook, Instagram, MyTarget и проходим обучение у их команд. Тестируем фичи в бета-версиях, недоступных для других рекламодателей.',
            img: '/img/target.svg'
          }
        ],
        rightSide: [
          {
            id: 4,
            title: 'Programmatic',
            text: 'Это платформа для закупки качественного инвентаря и реализации эффективных рекламных кампаний для релевантных сегментов пользователей на различных площадках. Запускаем огромное количество форматов и с помощью различных способов нацеливания на целевую аудиторию, охватываем самые важные сегменты аудитории для бизнеса.',
            img: '/img/programmatic.svg'
          },
          {
            id: 5,
            title: 'Мобильная реклама',
            text: 'Год от года этот рекламный канал становится все более креативным и интересным с точки зрения форматов и способов коммуникации с пользователями. Запускаем Баннерную, Видео и Поисковую рекламу на мобильных устройствах и конечно же рекламу мобильных приложений.',
            img: '/img/mobile.png'
          },
          {
            id: 6,
            title: 'Аналитика',
            text: 'Настраиваем аналитику на всех этапах проекта — от сбора данных до моделирования атрибуции и дашбордов. Google Analytics и Яндекс Метрика - инструменты позволяющие бизнесу отслеживать все действия в digital-пространстве. Мы подбираем самые эффективные решения для каждой конкретной задачи и ориентируемся на то что нужно и не нужно клиенту.',
            img: '/img/analytic.svg'
          }
        ]
      },
      isExpandedCard: false
    };
  },
  components: {ServiceCardsCol},
  methods: {
    expand(event) {
      let panel = event.target.nextElementSibling;
      this.isExpandedCard = true;

      event.target.classList.add("active");
      panel.style.maxHeight = panel.scrollHeight + "px";
    },
    reduce(event) {
      let panel = event.target.nextElementSibling;
      this.isExpandedCard = false;

      event.target.classList.remove("active");
      panel.style.maxHeight = null;
    },
    mobileScreenHandler(event) {
      let panel = event.target.nextElementSibling;

      if (!this.isExpandedCard) {
        event.target.classList.add('active');
        panel.style.maxHeight = panel.scrollHeight + "px";
        event.target.classList.remove('inactive');
      } else {
        event.target.classList.remove('active');
        panel.style.maxHeight = null;
        event.target.classList.add('inactive');
      }
    }
  }
};
</script>

<style lang="scss">
.reveal {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}

.reveal.active {
  transform: translateY(0);
  opacity: 1;
}

.services {
  background: url("./../assets/exp.png") center/cover no-repeat;
  padding: 64px 0 90px;
  position: relative;

  .figure_services {
    display: none;
  }

  &__card {
    margin: 0 24px 24px 0;
    cursor: pointer !important;
    transition: .2s all ease-in-out;
    transform: translateY(0);
    position: relative;
    z-index: 3;
  }

  .title {
    z-index: 1 !important;
    margin-top: 0;
  }

  &__list {
    margin-top: 26px;
    display: flex;
    flex-wrap: wrap;
  }

  &__accordion {
    max-width: 574px;
    width: 100%;
    background-color: transparent;
    color: #fff;
    padding: 45px 32px;
    border: 2px solid #fff;
    border-radius: 6px;
    text-align: left;
    outline: none;
    font-size: 30px;
    font-weight: 800;
    transition: 0.4s;
    position: relative;
    z-index: 15;

    &::after {
      content: '';
      width: calc(100% - 76px);
      height: 1px;
      background-color: #fff;
      position: absolute;
      bottom: 35px;
      left: 38px;
    }
  }

  button.active,
  &__accordion:hover {
    color: #00173F;
    background-color: #fff;
    border-radius: 6px 6px 0 0;

    &::after {
      background-color: #FF4E00;
    }
  }

  &__panel {
    transform: translateY(-7px);
    display: flex;
    align-items: flex-start;
    max-width: 574px;
    width: 100%;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
    color: #00173F;
    border-radius: 0 0 6px 6px;
    margin-top: -1px;
  }

  &__item-img {
    margin: 10px 32px 0 38px;
  }

  &__item-inner {
    margin-top: 10px;
    padding: 0 20px 130px 0;
  }

  &__list .service__card:nth-child(2) .services__item-inner {
    padding-bottom: 50px !important;
  }
}

@media (max-width: 1280px) {
  .services__list {
    flex-direction: column;
    align-items: center;
  }

  .services__card {
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  .services {
    padding-bottom: 26px;

    .title {
      margin-top: -42px !important;
    }

    .figure_services {
      width: 141px;
      position: absolute;
      top: -64px;
      left: -60px;
      display: block;
    }
  }
}

@media (max-width: 575px) {
  .services__accordion {
    font-size: 21px;
    padding: 31px 38px;

    &::after {
      bottom: 22px;
    }
  }

  .services__item-inner {
    padding: 0 20px 40px 0;
  }
}

</style>