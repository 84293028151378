<template>
  <header
      id="header"
      :class="isThankYouPage ? 'hidden' : 'header'"
  >
    <nav class="header__nav">
      <router-link to="/">
        <img
            @click="closeMenu"
            class="header__logo parallax"
            src="./../assets/logo.svg"
            alt="">
      </router-link>
      <ul
          class="header__menu"
          :class="burgerIsOpened ? 'header__menu_active' : null"
      >
        <li
            @click="closeMenu"
            class="header__menu-item">
          <router-link to="/performance" class="header__menu-link">для мсб</router-link>
        </li>
        <li
            @click="closeMenu"
            class="header__menu-item">
          <a
              v-if="this.$route.path === '/performance'"
              @click="anchor('experience')"
              class="header__menu-link"
          >о нас</a>
          <a
              v-else-if="this.$route.path === '/'"
              @click="anchor('experience')"
              class="header__menu-link"
          >о нас</a>
          <router-link
              v-else to="/"
              @click="anchor('experience')"
              class="header__menu-link"
          >о нас
          </router-link>
        </li>
        <li
            @click="closeMenu"
            class="header__menu-item">
          <a
              v-if="this.$route.path === '/performance'"
              @click="anchor('services')"
              class="header__menu-link"
          >услуги</a>
          <a
              v-else-if="this.$route.path === '/'"
              @click="anchor('services')"
              class="header__menu-link"
          >услуги</a>
          <router-link
              v-else to="/"
              @click="anchor('services')"
              class="header__menu-link"
          >услуги
          </router-link>
        </li>
        <li
            @click="closeMenu"
            class="header__menu-item">
          <a
              v-if="this.$route.path === '/performance'"
              @click="anchor('portfolio-anchor')"
              class="header__menu-link"
          >кейсы</a>
          <a
              v-else-if="this.$route.path === '/'"
              @click="anchor('portfolio-anchor')"
              class="header__menu-link"
          >кейсы</a>
          <router-link
              v-else to="/"
              @click="anchor('portfolio-anchor')"
              class="header__menu-link"
          >кейсы</router-link>
        </li>
        <li
            @click="closeMenu"
            class="header__menu-item">
          <a
              v-if="this.$route.path === '/performance'"
              @click="anchor('clients-anchor')"
              class="header__menu-link"
          >клиенты</a>
          <a
              v-else-if="this.$route.path === '/'"
              @click="anchor('clients-anchor')"
              class="header__menu-link"
          >клиенты</a>
          <router-link
              v-else to="/"
              @click="anchor('clients-anchor')"
              class="header__menu-link"
          >клиенты</router-link>
        </li>
        <li
            @click="closeMenu"
            class="header__menu-item">
          <a
              v-if="this.$route.path === '/performance'"
              @click="anchor('contacts-anchor')"
              class="header__menu-link"
          >контакты</a>
          <a
              v-else-if="this.$route.path === '/'"
              @click="anchor('contacts-anchor')"
              class="header__menu-link"
          >контакты</a>
          <router-link
              v-else to="/"
              @click="anchor('contacts-anchor')"
              class="header__menu-link"
          >контакты</router-link>
        </li>
        <li
            @click="closeMenu"
            class="header__menu-item">
          <a
              href="tel:+77005929383"
              class="binct-phone-number-1 header__menu-link header__menu-link_mobile"
          >+7 700 592 93 83</a>
        </li>
        <li class="header__menu-item">
          <Switch @darkBody="darken" :isDark="isDark"
          />
        </li>
      </ul>
      <SwitchMobile
          @darkBody="darken"
          :burgerIsOpened="burgerIsOpened"
          :burgerHandler="burgerHandler"
          :isDark="isDark"
      />
    </nav>
  </header>
</template>

<script>
import Switch from "@/components/Switch";
import SwitchMobile from "@/components/SwitchMobile";
import {mapMutations, mapState} from "vuex";

export default {
  name: 'Header',
  components: {Switch, SwitchMobile},
  data() {
    return {
      burgerIsOpened: false
    }
  },
  methods: {
    ...mapMutations({
      darken: 'darken',
      anchor: 'anchor',
    }),
    burgerHandler() {
      this.burgerIsOpened = !this.burgerIsOpened;
    },
    closeMenu() {
      this.burgerIsOpened = false;
    }
  },
  computed: {
    ...mapState({
      isDark: state => state.header.isDark,
      switched: state => state.header.switched,
      isThankYouPage: state => state.header.isThankYouPage
    }),
  },
};
</script>

<style lang="scss">

.header {
  position: relative;
  transition: .3s all ease;

  &__nav {
    max-width: 1921px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fafafa;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    padding: 11px 30px;
    z-index: 100;
    transition: .3s all ease;

    a {
      z-index: 101;
    }
  }

  .switch_mobile {
    display: none;
  }

  &__menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent !important;
    position: relative;
    z-index: 100;

    &_active {
      transform: translateY(220px) !important;

      .header__menu-item {
        margin-right: 0;
      }
    }

    &-item {
      font-weight: 700;
      margin-right: 50px;

      &:last-child {
        margin-right: 0;
      }

      &:last-child {
        .switch {
          position: relative;
          display: inline-block;
          width: 80px;
          height: 34px;
        }

        .switch input {
          display: none;
        }

        .slider {
          border: 1px solid #00173f;
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #fff;
          transition: 0.4s;
        }

        .slider:before {
          position: absolute;
          content: "";
          height: 26px;
          width: 26px;
          left: 4px;
          bottom: 4px;
          background-color: white;
          transition: 0.4s;
        }

        input:checked + .slider {
          background-color: #00173f;
        }

        input:focus + .slider {
          box-shadow: 0 0 1px #00173f;
        }

        input:checked + .slider:before {
          transform: translateX(35px) translateY(3px);
          background-color: #ff4e00;
          box-shadow: none;
        }

        .slider.round {
          border-radius: 34px;
        }

        .slider.round:before {
          width: 26px;
          height: 26px;
          margin: -72px 0px 2px 8px;
          border-radius: 50%;
          transform: rotateZ(90deg);
          box-shadow: 3px 7px 0 0 #ff4e00;
        }
      }
    }

    &-link {
      color: #00173f;
      text-transform: uppercase;
      cursor: pointer;
    }
  }

  .burger {
    display: none;
    height: 40px;
    width: 40px;
    cursor: pointer;
    position: relative;
    z-index: 100;
    margin-left: 30px;

    div {
      border-radius: 15px;
      margin: 10px 0;
      width: 100%;
      height: 3px;
      background-color: #00173F;
      transition: .3s all ease;
    }

    &_active {
      div:nth-child(1) {
        transform: rotate(45deg) translateY(18px);
      }

      div:nth-child(2) {
        transform: scale(0);
      }

      div:nth-child(3) {
        transform: rotate(-45deg) translateY(-18px);
      }
    }
  }
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

@media (max-width: 1280px) {

  .header {
    position: relative;

    .burger {
      display: block;
      margin-top: -5px;
    }
  }

  .header__menu-item:last-child {
    display: none;
  }

  .header__menu-item.switch_mobile {
    display: flex;
    align-items: center;
  }

  .header__menu {
    padding: 140px 0 80px !important;
    background-color: #fff !important;
    display: block;
    width: 100%;
    text-align: center;
    position: absolute;
    right: 0;
    transform: translateY(-320%);
    transition: .6s all ease;
  }

  .header__menu-item {
    font-size: 24px;
    font-weight: 700;
  }

  .header__menu-link {
    display: block;
    padding: 20px 0;
  }
}

@media (max-width: 767px) {
  .container {
    padding: 18px;
  }

  .header__nav {
    padding: 15px 18px;
  }

  .header-main__block {
    margin-right: 30px;
  }

  .header__menu {
    height: 2000px;

    &_active {
      transform: translateY(896px) !important;
      z-index: 30;
      position: fixed;
      overflow-y: hidden;
    }
  }

  .header .burger {
    width: 30px;
    margin-top: 3px;
    height: 30px;

    &_active {

      div:nth-child(1) {
        transform: translateY(7px) rotate(45deg);
      }

      div:nth-child(3) {
        transform: translateY(-11px) rotate(-45deg);
      }
    }

    div {
      margin: 6px 0;
    }
  }


  .switch {
    width: 60px !important;
    height: 30px !important;
  }

  .switch input {
    display: none;
  }

  .slider:before {
    height: 18px !important;
    width: 18px !important;
    left: 5px !important;
    bottom: 6px !important;
  }

  input:checked + .slider:before {
    transform: translateX(26px) translateY(3px) !important;
  }

  .slider.round:before {
    margin: -72px 0px 2px 6px !important;
  }

  .header__logo {
    content: url('./../assets/mobile-logo.svg');
    position: relative;
    z-index: 200;
  }

  .header-main {
    margin-top: 0 !important;

    &__text {
      margin-top: 39px !important;
    }
  }

  .header-main__stats {
    width: 352px !important;

    .header-main__block:first-child::after {
      right: -17px;
    }
  }
}

@media (max-width: 410px) {
  .header-main__stats {
    flex-wrap: wrap;
  }

  .header-main__block:last-child {
    margin-right: 0;
  }

  .header-main__block-number {
    font-size: 49px !important;
  }
}

</style>
