<template>
  <div
      class="modal"
      @click.self="closeModal"
      @wheel.prevent
      @touchmove.prevent
      @scroll.prevent
  >
    <Form
        title="заявка на сотрудничество"
        value="Отправить заявку"
        isModal
    />
  </div>
</template>

<script>
import Form from "@/components/Form";
import {mapMutations} from "vuex";

export default {
  name: "Modal",
  components: {
    Form
  },
  props: {
    utmData: Function,
    send: Function
  },
  methods: {
    ...mapMutations({
      closeModal: 'closeModal'
    })
  }
};
</script>

<style lang="scss">

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  padding: 30px;
  overflow-y: scroll;
  z-index: 200;

  .contacts__form {
    max-width: 600px;
    width: 100%;
    padding: 50px;
    background-color: #00173F;
    position: relative;
    margin-top: 40px;

    &_success {
      margin-top: 50px;
    }

    .submit {
      height: 53px;

      &:hover {
        background-color: #c03900;
      }
    }

    .title-text {
      font-size: 40px;
      text-transform: uppercase;
      margin-bottom: 57px;
    }
  }

  .form-close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }

  .form-input {
    background-color: #00173f;
    border-radius: 10px;
    border: 1px solid #fff;
    padding: 0 18px;
    height: 56px;
    margin-bottom: 20px;
    color: #fff;
    vertical-align: top;

    &_big {
      padding: 20px 20px 160px;
      position: relative;
      overflow-y: hidden;
      resize: none;

      &::placeholder {
        position: absolute;
        top: 18px;
      }
    }

    &::placeholder {
      font-size: 16px;
      font-weight: 400;
      color: #fff;
    }
  }

  .request-success {
    padding-top: 80px !important;

    p {
      font-size: 38px !important;
    }

    .title-text {
      text-align: center;
    }
  }
}

@media (max-width: 768px) {
  .modal {

    .request-form {
      padding: 60px 18px;
    }

    .title-text {
      font-size: 28px;
      margin-bottom: 28px;
    }

    .request-input_big {
      padding-bottom: 130px;
    }

    .request-success {

      p {
        font-size: 24px !important;
      }
    }
  }
}

@media (max-width: 410px) {
  .modal .title-text {
    font-size: 19px;
  }
}
</style>