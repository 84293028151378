import router from 'vue-router';

export const headerModule = {
  state: () => ({
    isDark: false,
    switched: false,
    isThankYouPage: false
  }),
  mutations: {
    anchor(state, block) {

      const scrollHandler = (block) => {
        const element = document.getElementById(block);
        const top = element.offsetTop;

        window.scrollTo(0, top - 40);
      };

      setTimeout(() => scrollHandler(block), 100);
    },
    darken(state, isBodyDark) {
      state.switched = isBodyDark;

      if (isBodyDark) {
        document.body.classList.add('darken');
      } else {
        document.body.classList.remove('darken');
      }
    },
    hideHeader(state) {
      state.isThankYouPage = !state.isThankYouPage;
    }
  },
};