<template>
  <footer class="footer footer_shadow">
    <div class="container">
      <div class="footer__img">
        <img class="footer__logo" src="./../assets/footer-logo.svg" alt="Ad Expert">
        <a @click="toTop" class="footer__up">
          <img src="./../assets/to-top.svg" alt="">
        </a>
      </div>
      <h4 class="footer__title">Наши контакты</h4>
      <div class="footer__contacts">
        <div class="footer__phones">
          <div class="footer__contact">
            <a href="tel:+77005929383" class="footer__link binct-phone-number-1">+7 700 592 93 83</a>
          </div>
        </div>
        <p class="footer__address">
          050004, Алматы, проспект
          Назарбаева, 65, офис 609
        </p>
        <a class="footer__contact" href="mailto:info@adexpert.kz">info@adexpert.kz</a>
      </div>
      <p class="footer__copyright">© 2022 ТОО «AdExpert»</p>
    </div>
    <a href="tel:+77005929383" class="footer__phone-number">+7 700 592 93 83</a>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    toTop() {
      window.scrollTo(0, 0)
    }
  }
};
</script>

<style lang="scss">
.footer {
  padding: 81px 0 70px;

  &_shadow {
    box-shadow: 0 40px 0 rgb(0 0 0 / 0%), 0 40px 40px rgb(0 0 0 / 7%) inset;
  }

  &__up {
    cursor: pointer;
  }

  &__img {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    margin-top: 60px;
    font-size: 18px;
    font-weight: 700;
    color: #6F6D7A;
  }

  &__phone-number {
    background-color: rgba(17, 64, 82, 0.8);
    width: 100%;
    height: 50px;
    color: #fff;
    font-size: 22px;
    font-weight: 400;
    position: fixed;
    bottom: 0;
    text-align: center;
    padding: 12px 0 12px 30px;
    z-index: 30;
    display: none;

    &::before {
      content: '';
      width: 20px;
      height: 20px;
      background: url("./../assets/handset.png") center/cover no-repeat;
      position: absolute;
      left: 50%;
      top: 14px;
      margin-left: -100px;
      transform: rotate(-5deg);
      filter: invert(100%) sepia(36%) saturate(0%) hue-rotate(25deg) brightness(104%) contrast(102%);
    }
  }

  &__contacts {
    display: flex;
    padding-top: 16px;
    margin-bottom: 72px;
  }

  &__phones {
    position: relative;
    margin: 0 140px 0 33px;

    &::before {
      content: '';
      width: 22px;
      height: 22px;
      background: url("./../assets/tel.svg") center/cover no-repeat;
      position: absolute;
      left: -38px;
      top: 0;
    }
  }

  &__contact, &__link {
    color: #6F6D7A;
    font-size: 16px;
    white-space: nowrap;
  }

  &__address {
    width: 250px;
    margin: 0 70px 0 33px;
    position: relative;

    &::before {
      content: '';
      width: 28px;
      height: 19px;
      background: url("./../assets/mail.svg") center/cover no-repeat;
      position: absolute;
      left: -40px;
    }
  }

  &__contact:nth-child(3) {
    position: relative;
    padding-left: 10px;

    &::before {
      content: '';
      width: 20px;
      height: 20px;
      background: url("./../assets/address.svg") center/cover no-repeat;
      position: absolute;
      left: -20px;
    }
  }

  &__copyright {
    position: relative;
    padding-top: 15px;
    font-size: 14px;
    font-weight: 300;
    color: #6F6D7A;

    &::before {
      content: '';
      width: 100%;
      height: 1px;
      background-color: #6F6D7A;
      position: absolute;
      left: 0;
      top: -20px;
    }
  }
}

@media (max-width: 767px) {
  .footer {
    padding: 50px 0;
  }

  .footer__link, .footer__contact {
    font-size: 16px;
    color: #00173f;
  }

  .footer__title {
    margin-top: 50px;
  }

  .footer__up {
    display: none;
  }

  .footer__contacts {
    flex-direction: column;

    * {
      margin-bottom: 20px;
    }
  }

  .footer__contact:nth-child(3) {
    padding-left: 35px;

    &::before {
      left: -8px;
    }
  }
}

@media (max-width: 575px) {
  .footer__phone-number {
    display: block;
  }
}
</style>