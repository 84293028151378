<template>
  <section id="clients-anchor" class="clients smooth parallax">
    <img class="dots header-dots" src="./../assets/dots.svg" alt="">
    <div ref="reveal" class="container reveal">
      <h1 class="title">наши <br>клиенты</h1>
      <img class="clients__img" src="./../assets/clients.png" alt="">
      <img class="figure figure_portfolio_large parallax figure_white" src="./../assets/figure.svg" alt="">
      <img class="dots footer-dots footer-dots_clients" src="./../assets/dots.svg" alt="">
      <img class="figure_experience-small figure parallax figure_clients-small header-figure_bottom-small" src="./../assets/figure.svg"
           alt="">
    </div>
  </section>
</template>

<script>
export default {
  name: "Clients",
};
</script>

<style lang="scss">
.clients {
  position: relative;
  background: url("./../assets/exp.png") center/auto no-repeat;
  background-size: auto 206%;

  .figure_portfolio_large {
    height: 404px;
    position: absolute;
    top: -213px;
    right: -468px;
    z-index: 5;
  }

  .title {
    margin-top: 60px;
  }

  .circle {
    height: 100%;
  }

  .figure_clients-white {
    top: 12px;
    right: 9px;
  }

  .figure_clients-small {
    top: -161px;
    right: 109px;
  }

  .figure_clients {
    position: absolute;
    bottom: -169px;
    right: 26px;
    width: 167px;
    z-index: 2;
  }

  .line {
    width: 100%;
    height: 2px;
    background-color: #ff4e00;
    margin-top: 30px;
  }

  &__img {
    margin-top: 50px;
    position: relative;
    margin-bottom: 70px;
    z-index: 3;
  }
}

@media (max-width: 1600px) {
  .figure_clients-white {
    top: 6px !important;
    right: 9px;
  }

  .figure_clients-small {
    top: -91px !important;
    right: 109px;
  }
}

@media (max-width: 768px) {
  .clients .title {
    margin-top: 0;
    padding: 0 !important;
  }

  .clients .circle {
    height: 105%;
  }

  .clients__img {
    content: url("./../assets/clients-mobile.png");
    margin: 50px auto 43px auto;
  }

  .figure_clients-white {
    right: 44px !important;
    top: -28px !important;
  }

  .figure_clients-small {
    right: 80px !important;
    top: -75px !important;
  }
}

</style>