<template>
  <section class="stages parallax">
    <div class="container reveal">
      <h1 class="title stages_title">как мы<br>работаем</h1>
      <StagesStep />
    </div>
    <img class="figure_experience-white parallax figure_stages-white figure_white"
         src="./../assets/figure.svg" alt="">
    <button @click="openModal" id="services-anchor" class="request">Оставить заявку</button>
    <Modal v-if="modalIsOpened" />
  </section>
</template>

<script>
import StagesStep from "@/components/StagesStep";
import Form from "@/components/Form";
import Modal from "@/components/Modal";
import {mapMutations, mapState} from "vuex";

export default {
  name: "Stages",
  components: {
    StagesStep,
    Form,
    Modal
  },
  methods: {
    ...mapMutations({
      openModal: 'openModal',
    }),
  },
  computed: {
    ...mapState({
      modalIsOpened: state => state.stages.modalIsOpened
    })
  }
}

</script>

<style lang="scss">

.stages {
  padding-bottom: 150px;
  background: url("./../assets/main.png") center/auto no-repeat;
  background-size: auto 254%;
  transition: 2s ease forwards;
  position: relative;

  .figure_stages-white {
    left: -213px;
    bottom: -165px;
    width: 543px;
    height: 460px;
  }

  .title {
    z-index: 3;
  }

  &__steps {
    margin-top: 80px;
    display: flex;
    align-items: flex-start;
  }

  &__step {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-right: 20px;
    opacity: 0;
    transform: translateX(-100%);
    z-index: 3;

    &:last-child .stages__img-arrow {
      display: none;
    }
  }

  &__text {
    transform: translateY(-100%);
    opacity: 0;
    margin-top: 30px;
  }

  &__title {
    font-size: 18px;
    font-weight: 700;
  }

  &__description {
    margin-top: 30px;
    width: 250px;
    line-height: 24px;
  }

  &__img {
    display: flex;
    align-items: center;

    &-pic {
      width: 86px;
      height: 86px;
      opacity: 0;
      transform: translateX(-100%);
    }

    &-arrow {
      opacity: 0;
      transform: translateX(-100%) scale(0);
    }
  }
}

.container.active .stages_expanded {

  .stages__step:nth-child(1) {
    opacity: 1;
    transform: translateX(0);
    transition: 1s all ease;

    .stages__img-pic {
      opacity: 1;
      transform: translateX(0);
      transition: 1s all ease;
    }

    .text_expanded {
      transition: 1s ease 1.5s, color .2s ease;
      opacity: 1;
      transform: translateX(0);
    }

    .arrow_expanded {
      opacity: 1;
      transform: translateX(0) scale(1);
      transition: 1s all ease .5s;
    }
  }

  .stages__step:nth-child(2) {
    opacity: 1;
    transform: translateX(0%);
    transition: 2s all ease 0.3s;

    .text_expanded {
      opacity: 1;
      transform: translateX(0);
      transition: 1s all ease 2.2s, color .2s ease;
    }

    .stages__img-pic {
      opacity: 1;
      transform: translateX(0);
      transition: 1s all ease 1s;
    }

    .arrow_expanded {
      opacity: 1;
      transform: translateX(0) scale(1);
      transition: 2s all ease 1s;
    }
  }

  .stages__step:nth-child(3) {
    opacity: 1;
    transform: translateX(0);
    transition: 2s ease forwards 1.2s;

    .text_expanded {
      opacity: 1;
      transform: translateX(0);
      transition: 1s all ease 3s, color .2s ease;
    }

    .stages__img-pic {
      opacity: 1;
      transform: translateX(0);
      transition: 1s all ease 2s;
    }

    .arrow_expanded {
      opacity: 1;
      transform: translateX(0) scale(1);
      transition: 2s all ease 2s;
    }
  }

  .stages__step:nth-child(4) {
    opacity: 1;
    transform: translateX(0);
    transition: 2s ease forwards 3.2s;

    .text_expanded {
      opacity: 1;
      transform: translateX(0);
      transition: 1s all ease 3.8s, color .2s ease;
    }

    .stages__img-pic {
      opacity: 1;
      transform: translateX(0);
      transition: 1s all ease 3s;
    }
  }

}

#services-anchor {
  position: absolute;
  left: 50%;
  margin-left: -95px;
  bottom: 53px;
}

.request {
  background-color: #FF4E00;
  border-radius: 10px;
  border: none;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  padding: 18px 27px;
  cursor: pointer;
  transition: .3s all ease-in;
  z-index: 3;

  &:hover {
    background-color: #00173f;
  }
}

@media (max-width: 1600px) {
  .figure_stages-white {
    left: -130px !important;
    bottom: -52px !important;
    width: 343px !important;
    height: 300px !important;
  }
}

@media (max-width: 1280px) {
  .stages__steps {
    flex-direction: column;
    align-items: center;
  }

  .stages__img {
    margin-right: 20px;
  }

  .stages__step {
    flex-direction: row;
    margin-right: 0;
    margin-bottom: 30px;
    transform: translateX(0);
    margin-top: -50px;

    &:last-child {
      margin-top: -13px;
    }
  }

  .stages__text {
    transform: translateY(0) translateX(-40%);
  }

  .stages__img {
    margin-right: 40px;
    flex-direction: column;

    &-pic {
      transform: translateX(0);
      margin-top: -50px;
    }

    &-arrow {
      content: url('./../assets/arrow-mobile.svg');
      margin-bottom: -20px;
      transform: translateX(0);
      margin-top: -50px;
    }
  }

  .stages {
    padding-bottom: 150px;

    .request {
      margin-top: 40px;
    }
  }

  .stages_expanded {

    .stages__step:nth-child(1) {
      margin-top: 0;

      .text_expanded {
        transform: translateX(0);
      }

      .arrow_expanded {
        margin-top: 0;
      }

      .stages__img-pic {
        margin-top: 0;
      }
    }

    .stages__step:nth-child(2) {
      margin-top: 0;

      .text_expanded {
        transform: translateX(0);
      }

      .arrow_expanded {
        margin-top: 0;
      }

      .stages__img-pic {
        margin-top: 0;
      }
    }

    .stages__step:nth-child(3) {
      margin-top: 0;

      .text_expanded {
        transform: translateX(0);
      }

      .arrow_expanded {
        margin-top: 0;
      }

      .stages__img-pic {
        margin-top: 0;
      }
    }

    .stages__step:nth-child(4) {
      margin-top: 0;

      .text_expanded {
        transform: translateX(0);
      }

      .arrow_expanded {
        margin-top: 0;
      }

      .stages__img-pic {
        margin-top: 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .figure_stages-white {
    left: -156px !important;
    bottom: -52px !important;
    width: 243px !important;
    height: 200px !important;
  }

  .stages {
    padding-bottom: 135px !important;
    height: 1150px;
  }

  .stages__steps {
    margin-top: 22px;
  }

  .stages__title {
    font-size: 16px;
  }

  .stages__description {
    font-size: 15px;
  }

  .stages__img-pic {
    min-width: 86px;
  }
}

@media (max-width: 375px) {
  .stages__img {
    margin-right: 15px;
  }

  .stages__description {
    width: inherit;
  }
}
</style>