<template>
  <section class="header-main parallax header-main_home">
    <div class="container">
      <h1 class="title">
        Казахстанское<br>
        Digital Агенство<br>
        <span>Adexpert</span>
      </h1>
      <img class="figure header-figure_main" src="./../assets/figure-mobile.svg" alt="">
      <p class="header-main__text">
        Ваш рост — это наш приоритет. Сейчас трудно выделиться в сети, не говоря уже о росте.
        Мы поможем вам сделать и то, и другое. Вместе мы выясним, что делает вас уникальными, определим вашу идеальную
        аудиторию и реализуем стратегии, которые соединяют
        эти две вещи.
        <br>
        <br>
        Если вам нужен системный подход к стимулированию роста, вы обратились по адресу.
      </p>
      <div class="header-main__stats">
        <div class="header-main__block">
          <span class="header-main__block-number">6</span>
          <p class="header-main__block-text">лет на<br>рынке</p>
        </div>
        <div class="header-main__block">
          <span class="header-main__block-number">50+</span>
          <p class="header-main__block-text">довольных<br>клиентов</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HeaderMainHome"
};
</script>