export const stagesModule = {
  state: () => ({
    modalIsOpened: false
  }),
  mutations: {
    openModal(state) {
      state.modalIsOpened = true;
    },
    closeModal(state) {
      state.modalIsOpened = false;
    },
  },
};