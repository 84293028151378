<template>
  <section class="smooth header-main parallax performance">
    <div class="container">
      <div class="header-main__title">
        <h1 class="title">
          Усильте Ваш Бизнес:
          <br>
          <span>Профессиональная Настройка Рекламы для Увеличения Продаж</span>
        </h1>
        <img class="figure parallax header-figure_main figure_white" src="./../assets/figure-mobile.svg" alt="">
        <p class="header-main__text">
          *Для малого и среднего бизнеса
        </p>
        <div class="header-main__stats">
          <div class="header-main__block">
            <span class="header-main__block-number">6</span>
            <p class="header-main__block-text">лет на<br>рынке</p>
          </div>
          <div class="header-main__block">
            <span class="header-main__block-number">50+</span>
            <p class="header-main__block-text">довольных<br>клиентов</p>
          </div>
        </div>
      </div>
      <Form
          title="оставить заявку"
          value="Отправить"
      />
    </div>
    <img class="figure parallax header-figure_bottom figure_white" src="./../assets/figure.svg" alt="">
    <img class="figure parallax header-figure_bottom-small" src="./../assets/figure.svg" alt="">
  </section>
</template>

<script>
import Form from "@/components/Form";

export default {
  name: "HeaderMain",
  components: {
    Form
  }
};
</script>

<style lang="scss">

.header-main {
  margin-top: 80px;
  padding-bottom: 60px;
  background: url("./../assets/exp.png") center/auto no-repeat;
  background-size: auto 170%;
  position: relative;
  z-index: 3;

  &_home {
    background: url("./../assets/main.png") center/auto no-repeat;

    .container {
      display: block !important;
    }

    .title span {
      color: #ff4e00 !important;
    }

    .header-main__block-number {
      color: #ff4e00;
    }

    .header-main__block-text {
      color: #00173f;
    }

    .header-main__text {
      color: #00173f;
    }
  }

  .title, .header-main__form {
    margin-top: 50px;
  }

  .title {
    font-size: 70px;
    color: #00173f !important;

    span {
      color: #fff;
    }
  }

  .header-main__block:first-child::after {
    background-color: #ff4e00;
  }

  .container {
    display: flex;
    align-content: flex-start;
    justify-content: space-between;
  }

  .contacts__form {
    margin-top: 58px;
    width: 50%;

    input {
      width: 400px !important;
    }

    input[name=email],
    input[name=comments] {
      display: none;
    }

    input[type=submit] {
      width: 180px !important;
      background-color: #fff;
      color: #00173f;

      &:hover {
        background-color: #00173f;
        color: #fff;
      }
    }

    .title-text {
      font-size: 50px;
      color: #F0F3F6;
      text-transform: uppercase;
      margin-bottom: 75px;
    }
  }

  &__text {
    margin-top: 53px;
    max-width: 770px;
    width: 100%;
    font-size: 18px;
    line-height: 24px;
    z-index: 10;
    position: relative;
    color: #fff;
  }

  &__stats {
    width: 600px;
    margin-top: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__block {
    display: flex;
    align-items: center;
    position: relative;

    &-number {
      font-size: 90px;
      font-weight: 900;
      color: #fff;
      margin-right: 20px;
    }

    &-text {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      position: relative;
      z-index: 10;
      color: #fff;
    }

    &:first-child::after {
      content: '';
      width: 2px;
      height: 67px;
      background-color: #fff;
      position: absolute;
      right: -76px;
      top: 20px;
    }
  }
}

.header-figure_bottom-small {
  width: 97px;
  height: 115px;
  bottom: 50px;
  right: 270px;
  display: none;
}

.header-figure_bottom {
  bottom: -100px;
  right: 100px;
  width: 211px;
  height: 250px;
  display: none;
}

.header-figure_main {
  right: -210px;
  top: 120px;
  width: 460px;
  height: 540px;
}

@media (max-width: 1280px) {
  .header-main {

    .container {
      flex-direction: column;
    }

    .contacts__form {
      margin-top: 19px;

      .title-text {
        margin-bottom: 30px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .header-figure_main {
    right: -210px;
    top: 120px;
    width: 363px;
    height: 354px;
  }

  .header-figure_bottom {
    bottom: -100px;
    right: -23px;
    width: 211px;
    height: 250px;
  }

  .header-figure_bottom-small {
    width: 97px;
    height: 115px;
    bottom: 50px;
    right: 114px;
  }
}

.performance .header-main__block:first-child::after {
  background-color: #fff;
  top: 19px;
}

@media (max-width: 768px) {
  .performance .header-main__block:first-child::after {
    top: 4px;
  }

  .header-figure_bottom-small {
    height: 74px;
    width: 51px;
    right: 60px;
    bottom: -26px;
  }

  .header-figure_bottom {
    width: 94px;
    height: 114px;
    bottom: -71px;
    right: 21px;
  }

  .header-dots {
    right: -150px;
    bottom: -140px;
  }

  .header-figure_main {
    right: -84px;
    top: 90px;
    width: 190px;
    height: 157px;
  }

  .header-main {
    padding-bottom: 30px !important;

    .container {
      padding: 0 17px;
    }

    .header-figure_main {
      right: -92px;
      top: 138px;
      width: 184px;
      height: 115px;
    }

    .title {
      margin-top: 30px;
    }

    .contacts__form {
      width: 100%;
      margin-top: 0;

      input {
        width: 100% !important;
      }

      .title-text {
        padding: 0 !important;
      }
    }

    &__text {
      margin-top: -22px !important;

      br {
        display: none;
      }
    }

    .title {
      margin-top: 0;
    }
  }

  .header-main__text {
    font-size: 16px;
    text-shadow: none;
  }

  .header-main__block-number {
    font-size: 60px;
  }

  .header-main__block-text {
    font-size: 16px;
  }

  .header-main__stats {
    width: 420px;
    margin-bottom: 18px;
  }

  .header-main__block:first-child::after {
    right: -37px;
    top: 12px;
    height: 50px;
  }
}
</style>