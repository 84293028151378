import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Performance from '../views/Performance';
import KaspiView from '../views/KaspiView';
import NskView from '../views/NskView';
import KmfView from '../views/KmfView';
import BiGroupView from '../views/BiGroupView';
import ThankYouPage from "@/views/ThankYouPage";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/performance',
    name: 'performance',
    component: Performance
  },
  {
    path: '/kaspi',
    name: 'kaspi',
    component: KaspiView
  },
  {
    path: '/kmf',
    name: 'kmf',
    component: KmfView
  },
  {
    path: '/nsk',
    name: 'nsk',
    component: NskView
  },
  {
    path: '/bi-group',
    name: 'bi-group',
    component: BiGroupView
  },
  {
    path: '/thank-you',
    name: 'thank-you',
    component: ThankYouPage
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to) {
    return {
      top: 0
    }
  }
})

export default router;
