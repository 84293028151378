<template>
  <section id="contacts-anchor" class="contacts parallax">
    <div class="container reveal">
      <div class="contacts__info">
        <h3 class="title-text">наши<br> контакты</h3>
        <div class="contacts__info-item">
          <a href="tel:+77005929383" class="contacts__info-link binct-phone-number-1">+7 700 592 93 83</a>
        </div>
        <div class="contacts__info-item">
          <a href="mailto:info@adexpert.kz" class="contacts__info-link">info@adexpert.kz</a>
        </div>
        <p class="contacts__info-item contacts__address">
          050004, Алматы,
          проспект Назарбаева,
          65, офис 609
        </p>
      </div>
      <Form
          title="заявка на сотрудничество"
          value="Отправить заявку"
      />
    </div>
    <div>
    </div>
  </section>
</template>

<script>
import Form from "@/components/Form";

export default {
  name: "Contacts",
  components: {Form},
};
</script>

<style lang="scss">
.contacts {
  position: relative;
  padding-bottom: 50px;

  .container {
    display: flex;
    justify-content: space-between;
  }

  .figure_contacts {
    position: absolute;
    left: -258px;
    top: 134px;
    height: 550px;
    width: 460px;
  }

  &__info-item {
    color: #00173f;
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 10px;
    line-height: 50px;

    a {
      color: #00173f;
    }
  }

  &__address {
    margin-top: 55px;
    max-width: 430px;
    width: 100%;
  }

  &__info-link {
    color: #fff;
  }

  .title-text {
    text-transform: uppercase;
    font-size: 49px;
    margin: 70px 0 30px;
    color: #00173f;
    z-index: 3 !important;
    position: relative;
  }

  &__form_success .title-text {
    margin-top: 0;
  }
}

@media (max-width: 1280px) {
  .contacts {

    .container {
      flex-direction: column;
      align-items: center;
    }

    .contacts__form {
      max-width: 575px;
      width: 100%;
    }

    &__info {
      order: 2;
      margin: 50px 0 0 -60px;

      .title-text {
        br {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .figure_clients {
    width: 87px !important;
    bottom: -102px !important;
  }

  .contacts {

    &__form {
      margin: 0;
    }

    .title-text, .title {
      margin-top: 0;
      padding: 0 !important;
    }

    .container {
      align-items: flex-start;
    }

    .title-text {
      font-size: 24px;
    }

    &__info {
      margin-left: 0;

      &-item {
        line-height: inherit;
        margin-bottom: 0;
      }

      &-link {
        font-size: 22px;
      }
    }

    &__address {
      margin-top: 25px;
      font-size: 22px;
    }
  }
}
</style>