<template>
  <section class="example example__kaspi">
    <div class="container">
      <img src="./../assets/figure-mobile.svg" alt="" class="figure parallax figure-example-header">
      <div id="example-name" class="example__name">
        <h1 class="title">Kaspi Магазин</h1>
        <img class="example__logo kaspi" alt="" src="./../assets/kaspi.svg">
      </div>
      <div class="example__line">
        <div class="example__line-title">
          Задачи
        </div>
        <div class="example__line-text">
          Увеличение количества лидов в Каспи Магазин и снижение стоимости

          <span>Было: Цена за лид 2000 руб</span>

          <span>Задача: Снизить цену лида до 300 руб</span>

        </div>
      </div>
      <div class="example__line">
        <div class="example__line-title">
          Использовали:
        </div>
        <div class="example__line-text">
          Инструмент MyTarget

          <span>Формат рекламы - мультиформат, карусель и баннер 240х400</span>
        </div>
      </div>
    </div>
    <!--    <img class="figure header-figure_bottom figure_white" src="/assets/figure.svg" alt="">-->
    <img class="figure parallax header-figure_bottom-small case_small" src="./../assets/figure.svg" alt="">
    <img src="./../assets/dots.svg" alt="" class="dots case-dots-header">
  </section>
  <Back/>
  <section class="realization smooth parallax">
    <div ref="reveal" class="container reveal">
      <h1 class="title">Реализация</h1>
      <p class="realization__text">Данная Performance кампания была направлена на получение лидов, однако данный
        тип рекламы увеличивает и узнаваемость бренда, что способствует увеличению интереса к бренду в поисковых
        сетях.</p>
      <div class="realization__cards kaspi-cards">
        <div class="realization__card">
          <div class="realization__card-number">1</div>
          <div class="realization__card-title">Запуск Мультиформата</div>
          <div class="realization__card-text">Продвижение Каспи Магазина
            в сети партнеров Mail.ru Group,
            с целью конверсия.
          </div>
        </div>
        <div class="realization__card">
          <div class="realization__card-number">2</div>
          <div class="realization__card-title">Оптимизация</div>
          <div class="realization__card-text">Первичная оптимизация для привлечения новых покупателей.
            По результатам мы смогли добиться стоимость лида в 600 руб.
          </div>
        </div>
        <div class="realization__card">
          <div class="realization__card-number">3</div>
          <div class="realization__card-title">Ремаркетинг</div>
          <div class="realization__card-text">Создание динамического ремаркетинга для привлечения покупателей
            взаимодействующих
            с Каспи Магазином. Создание новых форматов рекламы.
          </div>
        </div>
        <div class="realization__card">
          <div class="realization__card-number">4</div>
          <div class="realization__card-title">Анализ и Оптимизация</div>
          <div class="realization__card-text">Вторичная оптимизация по итогам которой использовались такие форматы
            рекламы как: карусель, баннер 240х400, мультиформат. Анализировались аудитории и сегменты потенциальных
            лидов.
          </div>
        </div>
      </div>
    </div>
    <img class="figure_result parallax figure_white" src="./../assets/figure.svg" alt="">
  </section>
  <section class="result result_kaspi parallax">
    <img class="dots dots-cases_footer" src="./../assets/dots.svg" alt="">
    <img class="figure_creatives-big parallax figure_white" src="./../assets/figure.svg" alt="">
    <img class="figure_creatives parallax figure_white" src="./../assets/figure.svg" alt="">
    <img class="dots dots-cases_footer-result" src="./../assets/dots.svg" alt="">
    <!--  <img class="thumbnail" src="./../assets/main.png" alt="image">-->
    <img class="figure_result-big parallax" src="./../assets/figure.svg" alt="">
    <div ref="container" class="container reveal">
      <h1 class="title">Результат</h1>
      <p class="result__text kaspi-title">
        В итоге стоимость конверсии снизилась
      </p>
      <p class="result__text_big kaspi-price">c<span>2000₽</span><br> до<span>300₽</span></p>
      <p class="result__text kaspi-text">(снизили стоимость лида в 7 раз)</p>
    </div>
  </section>
  <section class="creatives smooth creatives_kaspi parallax">
    <img class="dots dots-cases_header" src="./../assets/dots.svg" alt="">
    <div ref="creatives" class="container reveal">
      <!--    <img src="./../assets/figure-mobile.svg" alt="" class="figure figure-example-header">-->
      <h1 class="title">Креативы</h1>
      <p class="creatives__text">MyTarget - Карусель</p>
      <div class="creatives__cards kaspi-cards">
        <div class="creatives__card">
          <img class="creatives__card" src="./../assets/kaspi-1.png" alt="">
        </div>
        <div class="creatives__card">
          <img class="creatives__card" src="./../assets/kaspi-2.png" alt="">
        </div>
        <div class="creatives__card">
          <img class="creatives__card" src="./../assets/kaspi-3.png" alt="">
        </div>
      </div>
    </div>
  </section>
  <Footer/>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Back from "@/components/Back";

export default {
  name: 'KaspiView',
  components: {
    Back,
    Footer,
    Header
  },
};
</script>

<style lang="scss">

.creatives__card.img {
  display: none;
}

.creatives__card.div {
  z-index: 6 !important;
  position: relative;
}

.result {
  padding-bottom: 60px;
  position: relative;
  background: url("./../assets/main-white.png") center/cover no-repeat;
  background-size: auto 197%;

  .dots-cases_footer, .dots-cases_footer-result, .figure_creatives-big, .figure_creatives {
    display: none;
  }

  &_kaspi {
    .result__text_big span {
      margin-right: 20px !important;
    }
  }

  &_bi-group {
    .result__text_big {
      margin-top: 20px;
    }
  }

  .header-main__stats {
    width: inherit;
  }

  &__title_medium {
    font-weight: 500;
    font-size: 64px;
    margin: 10px 0;
  }

  .kaspi-price {
    br {
      display: none;
    }
  }

  .kaspi-text {
    margin-top: 20px;

    &::before {
      display: none;
    }
  }

  .kmf-stats .header-main__block-text {
    margin-left: 10px;
  }

  .header-main__block {
    width: 50%;
  }

  .header-main__block::after {
    display: none;
  }

  .header-main__block-text {
    font-weight: 600;
    font-size: 24px;
    color: #FF4E00;
  }

  .header-main__block-number {
    position: relative;

    &::after {
      content: '';
      width: 2px;
      height: 67px;
      background-color: #FF4E00;
      position: absolute;
      right: -16px;
      top: 20px;
    }
  }

  &__text {
    color: #FF4E00;
    font-size: 23px;
    font-weight: 600;
    margin-top: 100px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #C3C6CC;
      top: -50px;
    }

    &_big {
      font-size: 64px;
      font-weight: 700;
      color: #FF4E00;

      span {
        text-decoration: underline;
        margin-left: 27px;
        font-weight: 800;
      }
    }
  }
}

.kmf-stats {
  .header-main__block-number {
    color: #ff4e00;
  }
}

.nsk-results {
  .header-main__block-text {
    color: #FF4E00 !important;
  }
}

@media (max-width: 1024px) {
  .kmf-stats .header-main__block-text {
    margin-left: 10px;
  }

  .kmf-cards .creatives__card img {
    max-width: 500px !important;
    z-index: 6 !important;
  }

  .nsk-results {

    .header-main__block-number::after {
      left: -75px !important;
    }

    #total::after {
      left: -59px !important;
    }

    .header-main__block:nth-child(2) {
      .header-main__block-number::after {
        left: -124px !important;
      }
    }
  }

  .result__title_medium {
    margin-top: 40px;
    font-weight: 600;
  }

  .kmf-stats {
    flex-direction: column;

    .header-main__block {
      flex-direction: column;
      text-align: center;
      margin-right: 0;

      &-number {
        margin: 0 0 20px 0;
      }

      &:nth-child(2) .header-main__block-number::after {
        left: -77px;
      }

      &-number::after {
        transform: rotate(0);
        width: 406px;
        height: 1px;
        top: 118px;
        left: -34px;
      }
    }
  }
}

@media (max-width: 768px) {

  .dots-cases_footer, .dots-cases_footer-result, .figure_creatives-big, .figure_creatives {
    display: block !important;
  }

  .result {
    .dots-cases_footer {
      display: block;
    }
  }

  .result_bi-group {

    .dots-cases_footer {
      bottom: auto;
      top: -150px;
    }
  }

  .result_kmf {
    .dots-cases_footer {
      bottom: auto;
      top: -150px;
    }
  }

  .nsk-results {
    .dots-cases_footer {
      bottom: auto;
      top: -150px;
    }
  }

  .result {
    .figure_creatives-big {
      z-index: 2;
      width: 97px;
      height: 115px;
      left: auto;
      right: 20px;
      top: auto;
      bottom: -70px;
    }

    .figure_creatives {
      z-index: 2;
      position: absolute;
      width: 30px;
      height: 44px;
      left: auto;
      bottom: -68px;
      right: 14px;
      top: auto;
    }

    .dots-cases_footer-result {
      display: block;
      right: -120px;
      bottom: -152px;
    }

    .title {
      margin-top: -33px;
    }
  }

  .result__text {
    margin-top: 37px;
  }

  .result_kmf {
    .result__text {
      margin-top: 38px;
    }
  }

  .result_kaspi {

    .dots-cases_footer {
      top: -130px;
    }
  }

  .result__title_medium {
    font-size: 34px;
  }

  .result__text {
    width: 100%;
    text-align: inherit !important;
  }

  .kaspi-text {
    display: none;
  }

  .kaspi-price {
    br {
      display: block !important;
    }

    span {
      text-decoration: none;
    }
  }

  #nsk-stats .header-main__block-number::after {
    left: -62px !important;
  }

  #nsk-stats #total::after {
    left: -49px !important;
  }

  #nsk-stats .header-main__block:nth-child(2) .header-main__block-number::after {
    left: -90px !important;
  }

  #nsk-stats .header-main__block:nth-child(2) .header-main__block-number::after {
    left: -100px !important;
  }

  .kmf-stats {
    align-items: center !important;
    width: 100% !important;

    .header-main__block {
      width: inherit;

      .header-main__block-number::after {
        width: 286px;
        top: 77px;
      }

      &:nth-child(2) {
        margin-top: 40px;

        p {
          width: 282px;
        }
      }

      &:nth-child(2) .header-main__block-number::after {
        left: -64px;
        width: 286px;
      }

    }
  }
  .result__text {
    font-size: 16px;
    margin-top: 80px;
    text-align: center;

    &::before {
      top: -40px;
    }
  }

  .result__text_big {
    font-size: 48px;
    font-weight: 600;
    text-align: center;
    margin-top: 10px;
  }
}

.realization {
  padding-bottom: 80px;
  position: relative;
  background: url("./../assets/exp.png") center/auto no-repeat;
  background-size: auto 150%;

  .kaspi-cards .realization__card {
    height: 550px;
  }

  &__text {
    margin: 114px 0 70px;
    font-size: 18px;
    font-weight: 600;
    position: relative;

    &:after {
      content: '';
      width: 100%;
      height: 2px;
      background-color: #fff;
      position: absolute;
      top: -50px;
      left: 0;
    }
  }

  &__cards {
    display: flex;
  }

  &__card {
    border-radius: 10px;
    border: 2px solid #fff;
    padding: 20px;
    justify-content: flex-start;
    width: 286px;
    height: 407px;
    margin-right: 15px;
    z-index: 3;

    &-number {
      border-radius: 50%;
      font-weight: 800;
      font-size: 18px;
      border: 1px solid #fff;
      width: 40px;
      height: 40px;
      text-align: center;
      padding-top: 8px;
    }

    &-title {
      font-weight: 800;
      font-size: 16px;
      margin-top: 21px;
    }

    &-text {
      font-weight: 400;
      margin-top: 70px;
      position: relative;
      line-height: 24px;

      &::before {
        content: '';
        width: 100%;
        height: 1px;
        background-color: #fff;
        position: absolute;
        top: -34px;
        left: 0;
      }
    }
  }
}

@media (max-width: 1279px) {
  .realization__cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    row-gap: 20px;
  }

  .realization__card {
    width: 100%;
    height: 400px !important;
  }
}

@media (max-width: 1024px) {
  .bi-group__realization {
    .title::after {
      bottom: -30px;
    }
  }

  .bi-group__text {
    margin: 62px 0 38px;
  }
  .realization__cards {
    grid-template-columns: 1fr;
  }

  .kaspi-cards .realization__card,
  .kmf-cards .realization__card {
    height: auto !important;
  }

  .bi-group__cards {
    .realization__card {
      height: auto !important;
    }
  }
}

@media (max-width: 768px) {
  .realization {
    padding-top: 0;

    &__text {
      margin-top: 50px;
    }
  }

  .realization .thumbnail {
    content: url("./../assets/mobile-realization.png") !important;
  }

  .realization__cards {
    margin-bottom: 18px;
  }

  .realization__card {
    height: auto;
    width: 100%;
  }
}

.creatives {
  padding-top: 23px;
  padding-bottom: 80px;
  position: relative;
  background: url("./../assets/exp.png") center/cover no-repeat;
  background-size: auto 150%;

  .title {
    margin-top: 40px;
  }

  &__text {
    margin: 100px 0 40px 0;
    font-size: 24px;
    font-weight: 900;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #fff;
      top: -50px;
      left: 0;
    }
  }

  .nsk-cards {
    justify-content: flex-start;

    .creatives__card {
      margin-right: 150px;
    }
  }

  &__cards {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    z-index: 3;
    position: relative;
  }

  &__card {

    &:nth-child(1) {

      p {
        margin-bottom: 31px;
      }
    }

    &:nth-child(2) {

      p {
        margin-bottom: 88px;
      }
    }

    &:nth-child(3) {

      p {
        margin-bottom: 117px;
      }
    }

    p {
      font-size: 24px;
      font-weight: 700;
      width: 200px;
    }
  }

  .kaspi-cards img {
    height: 335px;
    z-index: 3;
    position: relative;
  }
}

@media (max-width: 1024px) {
  .kmf-creatives .creatives__cards {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .creatives__card {
      max-width: 400px;
      width: 100%;

      &:first-child img {
        height: 290px;
      }

      img {
        margin-bottom: 0;
      }
    }
  }

  .creatives__cards {
    flex-direction: column;
    align-items: center;
  }

  .kaspi-cards {
    .creatives__card {
      width: 300px;
    }
  }

  .nsk-cards {
    .creatives__card {
      margin: 0 0 20px !important;
    }
  }

  .creatives__card {

    margin-bottom: 30px;

    p {
      width: 100%;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .kmf-creatives {
    .creatives__card:nth-child(2) {
      p {
        margin-bottom: 25px;
      }
    }

    .creatives__card:nth-child(3) {
      p {
        margin-bottom: 25px;
      }
    }
  }
}

@media (max-width: 768px) {
  .creatives {
    padding-top: 0;
    margin-top: -40px;
  }

  .creatives_kaspi {
    padding-bottom: 0 !important;
  }

  .creatives__text {
    margin-top: 23px !important;
  }

  .creatives__cards {
    margin-top: 30px;
  }

  .creatives__text {
    font-size: 20px;
    font-weight: 900;
    margin-top: 70px;

    &::before {
      height: 1px;
      top: -35px;
    }

    br {
      display: none;
    }
  }
}

@media (max-width: 410px) {

  .creatives__cards {
    margin-top: 30px;

    img {
      max-width: 400px;
    }
  }
}

.back {
  position: fixed;
  left: 100px;
  top: 185px;
  z-index: 4;
}

.example {
  padding: 100px 0 60px;
  position: relative;

  .figure .figure-example-header {
    display: none;
  }

  .figure-example-header {
    display: none;
    right: -62px;
    top: 96px;
    z-index: 1;
  }

  .case-dots-header {
    right: -500px;
    bottom: -800px;
  }

  .figure-example {
    right: 100px;
    bottom: -110px;
    height: 250px;
    width: 206px;
  }

  .figure-example-small {
    height: 98px;
    width: 82px;
    right: 40px;
    bottom: 60px;
  }

  &__name {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    margin-bottom: 40px;
  }

  &__line {
    display: flex;
    align-items: center;
    padding: 50px 0;
    border-top: 2px solid #C3C6CC;
    position: relative;

    &-nsk {
      padding: 50px 0;

      .example__line-text {
        margin-top: 0;
        width: 100%;
      }

      .example__line-title {
        width: 320px;
        margin-right: 0;
      }
    }

    &:nth-child(2) {
      padding: 54px 0;
      margin-top: 44px;

      .example__line-text {
        margin-top: 0;
      }
    }

    &-title {
      font-size: 24px;
      font-weight: 900;
      width: 280px;
    }

    &-text {
      font-size: 18px;
      font-weight: 700;
      //margin-top: 45px;

      span {
        font-weight: 400;
        font-size: 16px;
        display: block;
        margin-top: 25px;
      }
    }
  }
}

@media (max-width: 1600px) {
  .back {
    display: none;
  }

  .example__line-text {
    margin-right: 0 !important;
  }
}

@media (max-width: 1280px) {
  .example__kaspi {
    .example__line-title {
      width: 330px;
    }

    .example__line-text {
      width: 100%;
    }
  }
}

@media (max-width: 767px) {
  .example {
    .figure-example-header {
      display: block;
      width: 110px;
      height: 150px;
      right: -50px;
    }

    .case-dots-header {
      width: 300px;
      height: 300px;
      right: -117px;
      bottom: -144px;
    }

    .figure-example {
      height: 64px;
      width: 54px;
      bottom: -36px;
    }

    .figure-example-small {
      height: 25px;
      width: 20px;
      right: 139px;
      bottom: 10px;
    }
  }

  .example {
    padding-bottom: 0 !important;
  }

  .example__line {
    flex-direction: column;
  }

  .example__name {
    flex-direction: column;
    margin-top: -20px;
  }

  .example .title {
    order: 2;
    margin-right: auto;
    margin-top: 49px;
  }

  .example__logo {
    order: 1;
  }

  .example__line {
    padding: 30px 0 !important;
    align-items: flex-start;

    &-title {
      margin-bottom: 25px;
      font-size: 20px;
    }

    &-text {
      font-size: 16px;

      &:nth-child(2) {
        margin-top: 0;

        span {
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
