<template>
  <div class="thank-you">
    <router-link to="/">
      <img class="back" src="./../assets/back.svg" alt="">
    </router-link>
    <h1 class="title">ЗАЯВКА ОТПРАВЛЕНА<br>УСПЕШНО!</h1>
    <img class="dots top" src="./../assets/dots.svg" alt="">
    <img class="dots bottom" src="./../assets/dots.svg" alt="">
    <img class="figure figure_top big" src="./../assets/figure.svg" alt="">
    <img class="figure figure_bottom big" src="./../assets/figure.svg" alt="">
    <img class="figure figure_bottom small" src="./../assets/figure.svg" alt="">
  </div>
</template>

<script>
import Back from "@/components/Back";
import {mapMutations, mapState} from "vuex";

export default {
  name: "ThankYouPage",
  components: {Back},
  methods: {
    ...mapMutations({
      hideHeader: 'hideHeader',
    }),
  },
  mounted() {
    this.hideHeader();
  },
  beforeRouteLeave(to, from, next) {
    this.hideHeader();
    next();
  }
};
</script>

<style lang="scss">
.thank-you {
  height: 100vh;
  max-width: 3840px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  .title {
    text-align: center;
    font-size: 60px;
  }

  .back {
    display: block;
    top: 100px;

    @media (max-width: 768px) {
      left: 29px;
      top: 32px;
    }
  }

  .dots {
    position: absolute;
    width: 500px;
    height: 500px;
  }

  .dots.top {
    right: -256px;
    top: -186px;
  }

  .dots.bottom {
    left: -256px;
    bottom: -186px;
  }

  .figure {
    position: absolute;
  }

  .figure_top {
    top: 80px;
    left: 200px;
  }

  .figure_bottom {
    bottom: 160px;
    right: 250px;
  }

  .big {
    width: 230px;
  }

  .small {
    bottom: 130px;
    right: 260px;
  }

  @media (max-width: 1279px) {
    .big {
      width: 140px;
    }

    .small {
      width: 90px;
      bottom: 130px;
    }

    .figure_top {
      top: 151px;
      left: 30px;
    }

    .figure_bottom {
      right: 70px;
      bottom: 148px
    }

    .figure_bottom.small {
      bottom: 128px
    }
  }

  @media (max-width: 575px) {
    .title {
      padding: 0 20px !important;
    }

    .dots.top {
      right: -178px;
      top: -253px;
    }

    .dots.bottom {
      left: -178px;
      bottom: -253px;
    }
  }
}
</style>