<template>
  <div class="header__menu-item switch_mobile">
    <label class="switch">
      <input
          ref="switcher"
          @change="darken"
          :checked="isChecked"
          type="checkbox"
      >
      <span class="slider round"></span>
    </label>
    <div
        @click="burgerHandler"
        class="burger"
        :class="burgerIsOpened ? 'burger_active' : ''"
      >
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SwitchMobile",
  props: {
    burgerHandler: {
      type: Function,
      required: true
    },
    burgerIsOpened: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      darker: false,
      isChecked: false,
    };
  },
  methods: {
    darken() {
      this.isChecked = !this.isChecked;
      this.darker = !this.darker;
      this.$emit('darkBody', this.darker);
    }
  },
  beforeMount() {
    if (document.body.classList.contains('darken')) {
      this.isChecked = true;
      this.darker = true;
    } else {
      this.isChecked = false;
      this.darker = false;
    }
  }
};
</script>