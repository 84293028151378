<template>
  <section id="portfolio-anchor" class="portfolio">
    <div class="container reveal">
      <h1 class="title">кейсы</h1>
      <div class="portfolio__cases">
        <router-link to="/kaspi" class="portfolio__card">
          <div class="portfolio__case">
            <span class="portfolio__name">Kaspi Магазин</span>
            <span class="portfolio__link portfolio__link_visible">Подробнее</span>
          </div>
        </router-link>
        <router-link to="/kmf" class="portfolio__card">
          <div class="portfolio__case">
            <span class="portfolio__name">KMF</span>
            <span class="portfolio__link portfolio__link_visible">Подробнее</span>
          </div>
        </router-link>
        <router-link to="/nsk" class="portfolio__card">
          <div class="portfolio__case">
            <span class="portfolio__name">NSK</span>
            <span class="portfolio__link portfolio__link_visible">Подробнее</span>
          </div>
        </router-link>
        <router-link to="/bi-group" class="portfolio__card">
          <div class="portfolio__case">
            <span class="portfolio__name">BI Group</span>
            <span class="portfolio__link portfolio__link_visible">Подробнее</span>
          </div>
        </router-link>
      </div>
    </div>
    <img class="figure_portfolio_small_white figure parallax figure_white" src="./../assets/figure.svg" alt="">
    <img class="figure_portfolio_small figure parallax" src="./../assets/figure.svg" alt="">
  </section>
</template>

<script>
export default {
  name: "Portfolio",
};
</script>

<style lang="scss">
.portfolio {
  padding: 130px 0 70px;
  position: relative;
  background: url('./../assets/main.png') center/auto no-repeat;

  .figure_portfolio_small {
    height: 115px;
    position: absolute;
    bottom: 97px;
    right: 132px;

    &_white {
      display: none;
    }
  }

&__cases {
   margin-top: 40px;
   display: flex;
   flex-wrap: wrap;
 }

&__link {
   font-size: 14px;
   font-weight: 700;
   color: #fff;
   padding: 10px 20px;
   background-color: #FF4E00;
   border-radius: 6px;
   transition: .2s all ease;
   margin-top: 20px;

&:hover {
   background-color: #00173F;
   color: #fff;
 }
}

&__card {
   height: 240px;
   border: 2px solid #FF4E00;
   font-size: 48px;
   font-weight: 800;
   max-width: 574px;
   width: 100%;
   border-radius: 6px;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 20px;
   margin: 0 32px 32px 0;
   transition: .3s all ease;
   z-index: 4;
   position: relative;

&:hover {
   background-color: #FF4E00;

span {
  color: #fff;
}
}

&:hover .portfolio__name {
   transform: translateY(0);
 }

&:hover .portfolio__link {
   opacity: 1;
   pointer-events: auto;
 }

&:hover .portfolio__link {
   background-color: #fff;
   color: #00173f;
 }

&:nth-child(2) {
   margin-right: 0;
 }

&:nth-child(4) {
   margin-right: 0;
 }
}

&__case {
   border-radius: 6px;
   border: 2px solid #fff;
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   z-index: 4;
 }

&__name {
   color: #00173F;
   text-align: center;
   transform: translateY(28px);
   transition: .3s all ease;
 }

&__link {
   opacity: 0;
   pointer-events: unset;
   transition: .3s all ease;
 }
}

@media (max-width: 1750px) {
  .figure_portfolio_small {
    display: none;
  }
}

@media (max-width: 1280px) {
  .portfolio__cases {
    flex-direction: column;
    align-items: center;
  }

  .portfolio__card {
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  .portfolio {
    padding: 0 0 14px;

    .figure_portfolio_small_white {
      //top: -24px;
      //right: 32px;
    }

    .figure_portfolio_small {
      display: block;
      height: 70px;
      position: absolute;
      top: 12px;
      right: 35px;

      &_white {
        display: block;
        height: 50px;
        position: absolute;
        top: -17px;
        right: 50px;
      }
    }
  }

  .portfolio__card {
    height: 200px;
  }

  .portfolio__card:nth-child(1) {
    height: 220px;
  }

  .portfolio__name {
    font-size: 36px;
    transform: translateY(0);
  }

  .portfolio__link_visible {
    opacity: 1;
    pointer-events: auto;
  }
}
</style>