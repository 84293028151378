<template>
  <div :class="className">
    <div
        v-for="card in cards"
        class="services__card"
        key="card.id"
    >
      <button
          @mouseover="expand"
          @mouseleave="reduce"
          @click="mobileScreenHandler"
          class="services__accordion"
      >
        {{ card.title }}
      </button>
      <div class="services__panel">
        <img class="services__item-img" :src="card.img" alt="Контекстная реклама">
        <p class="services__item-inner">{{ card.text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceCardsCol",
  props: {
    expand: {
      type: Function,
      required: true
    },
    reduce: {
      type: Function,
      required: true
    },
    mobileScreenHandler: {
      type: Function,
      required: true
    },
    cards: {
      type: Array,
      required: true
    },
    className: {
      type: String,
      required: true
    },
  },
};
</script>

<style lang="scss">
.inactive {
  &:hover {
    background-color: #ff4e00 !important;
    color: #fff !important;

    &::after {
      background-color: #fff !important;
    }
  }
}
</style>