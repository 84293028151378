<template>
  <div>
    <label id="switch" class="switch">
      <input ref="switcher" @change="darken" type="checkbox" :checked="isChecked">
      <span class="slider round"></span>
    </label>
  </div>
</template>

<script>
export default {
  name: "Switch",
  props: ['isDark'],
  data() {
    return {
      darker: false,
      isChecked: false
    }
  },
  methods: {
    darken() {
      this.isChecked = !this.isChecked;
      this.darker = !this.darker;

      this.$emit('darkBody', this.darker)
    }
  },
  beforeMount() {
      if (document.body.classList.contains('darken') ) {
        this.isChecked = true;
        this.darker = true;
      } else {
      this.isChecked = false;
      this.darker = false;
    }
  }
};
</script>
