import { createStore } from 'vuex'
import { formModule } from '@/store/formModule';
import { stagesModule } from "@/store/stagesModule";
import {headerModule} from "@/store/headerModule";

export default createStore({
  modules: {
    form: formModule,
    stages: stagesModule,
    header: headerModule,
  }
})
