<template>
  <section class="example parallax">
    <div class="container">
      <img src="./../assets/figure-mobile.svg" alt="" class="figure parallax figure-example-header">
      <div id="example-name" class="example__name">
        <h1 class="title">NSK</h1>
        <img class="example__logo" alt="" src="./../assets/nsk.svg">
      </div>
      <div class="example__line example__line-nsk">
        <div class="example__line-title">
          Задачи
        </div>
        <div class="example__line-text">
          Привлечение целевых клиентов для КАСКО с ценой за лид не более 5 000 тнг

        </div>
      </div>
      <div class="example__line example__line-nsk">
        <div class="example__line-title">
          Использовали:
        </div>
        <div class="example__line-text">
          Инструмент Google Ads
        </div>
      </div>
    </div>
    <!--    <img class="figure header-figure_bottom figure_white" src="/assets/figure.svg" alt="">-->
    <img class="figure parallax header-figure_bottom-small case_small" src="./../assets/figure.svg" alt="">
    <img src="./../assets/dots.svg" alt="" class="dots case-dots-header">
  </section>
  <Back/>
  <section class="realization smooth parallax realization-nsk">
    <div ref="reveal" class="container reveal">
      <h1 class="title">Реализация</h1>
      <p class="realization__text">Данная Performance кампания была направлена на получение лидов (заявок) на
        сайте.</p>
      <div class="realization__cards kaspi-cards">
        <div class="realization__card">
          <div class="realization__card-number">1</div>
          <div class="realization__card-title">Анализ рынка</div>
          <div class="realization__card-text">Был произведен комплексный анализ рынка и запросов в поисковиках по
            тематике автострахования.
          </div>
        </div>
        <div class="realization__card">
          <div class="realization__card-number">2</div>
          <div class="realization__card-title">Стратегия и семантика</div>
          <div class="realization__card-text">Разработка архитектуры рекламного кабинета и семантики ключевых слов для
            использования в кампании. Сегментирование ключевых слов под адаптированные объявления. Сегментирование
            запросов под определенные целевые аудитории для показа наиболее релевантного объявления.
          </div>
        </div>
        <div class="realization__card">
          <div class="realization__card-number">3</div>
          <div class="realization__card-title">Запуск кампании</div>
          <div class="realization__card-text">

            Запуск кампании с уже готовой широкой семантикой.
          </div>
        </div>
        <div class="realization__card">
          <div class="realization__card-number">4</div>
          <div class="realization__card-title">Анализ и Оптимизация</div>
          <div class="realization__card-text">Анализ поисковых запросов, анализ эффективности групп объявлений и
            объявлений, выявление нерелевантных ключевых запросов и последующее минусование их.
          </div>
        </div>
      </div>
    </div>
    <img class="figure_result figure figure_white" src="./../assets/figure.svg" alt="">
    <img class="dots dots-cases_footer" src="./../assets/dots.svg" alt="">
  </section>
  <section id="nsk-stats" class="result nsk-results parallax">
    <img class="dots dots-cases_footer" src="./../assets/dots.svg" alt="">
    <img class="figure_creatives-big figure figure_white" src="./../assets/figure.svg" alt="">
    <img class="figure_creatives figure figure_white" src="./../assets/figure.svg" alt="">
    <img class="dots dots-cases_footer-result" src="./../assets/dots.svg" alt="">
    <div ref="container" class="container reveal">
      <h1 class="title">Результат</h1>
      <p class="result__text"></p>
      <div class="header-main__stats kmf-stats">
        <div class="header-main__block">
          <span class="header-main__block-number">5 250</span>
          <p class="header-main__block-text">кликов</p>
        </div>
        <div class="header-main__block">
          <span class="header-main__block-number">190</span>
          <p class="header-main__block-text">
            заявок стоимостью 4 300 тнг
            по небрендовым запросам
          </p>
        </div>
      </div>
      <div class="header-main__stats kmf-stats">
        <div class="header-main__block">
          <span class="header-main__block-number">7 690</span>
          <p class="header-main__block-text">кликов</p>
        </div>
        <div class="header-main__block">
          <span class="header-main__block-number">416</span>
          <p class="header-main__block-text">заявок стоимостью 1 294 тнг
            по брендовым запросам</p>
        </div>
      </div>
      <div class="result__title_medium reveal">Общий результат</div>
      <div class="header-main__stats kmf-stats">
        <div class="header-main__block">
          <span id="total" class="header-main__block-number">12 940</span>
          <p class="header-main__block-text">кликов</p>
        </div>
        <div class="header-main__block">
          <span class="header-main__block-number">606</span>
          <p class="header-main__block-text">
            конверсий стоимостью
            2 236 тнг
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="creatives smooth parallax creatives_nsk">
    <img class="dots dots-cases_header" src="./../assets/dots.svg" alt="">
    <div ref="creatives" class="container reveal">
      <h1 class="title">Креативы</h1>
      <p class="creatives__text">Facebook - Лента</p>
      <div class="creatives__cards nsk-cards">
        <div class="creatives__card">
          <img class="creatives__card" src="./../assets/nsk-1.png" alt="">
        </div>
        <div class="creatives__card">
          <img class="creatives__card" src="./../assets/nsk-2.png" alt="">
        </div>
      </div>
      <p class="creatives__text">Google - Поиск</p>
      <div class="creatives__card">
        <img class="creatives__card" src="./../assets/nsk-3.png" alt="">
      </div>
    </div>
  </section>
  <Footer/>

</template>

<script>
import Back from "@/components/Back";
import Footer from "@/components/Footer";
import Header from "@/components/Header";

export default {
  name: "NskViews",
  components: {
    Footer,
    Back,
    Header
  },
};
</script>