<template>
  <section id="team" class="team smooth parallax">
    <img class="dots header-dots" src="./../assets/dots.svg" alt="">
    <div ref="reveal" class="container">
      <h3 class="title-text">Получите команду из:</h3>
      <div class="team-container">
        <div class="team__card">
          <img src="./../assets/manager.svg" alt="" class="team__img">
          <div class="card__text-block">
            <p class="card__title">Менеджер проекта</p>
            <ul class="card__list">
              <li class="card__item">Обсуждение и контроль выполнения KPI</li>
              <li class="card__item">Коммуникация с клиентом</li>
              <li class="card__item">Подготовка отчетов для клиента</li>
            </ul>
          </div>
        </div>
        <div class="team__card">
          <img src="./../assets/ad-spec.png" alt="" class="team__img">
          <div class="card__text-block">
            <p class="card__title">Специалист по настройке рекламы</p>
            <ul class="card__list">
              <li class="card__item">Помощь в составлении стратегии продвижения</li>
              <li class="card__item">Помощь в настройке аналитики</li>
              <li class="card__item">Создание рекламных кампаний</li>
              <li class="card__item">Анализ рекламных кампаний</li>
              <li class="card__item">Оптимизация рекламных кампаний</li>
            </ul>
          </div>
        </div>
        <div class="team__card">
          <img src="./../assets/ad-boss.png" alt="" class="team__img">
          <div class="card__text-block">
            <p class="card__title">Начальник рекламного отдела</p>
            <ul class="card__list">
              <li class="card__item">Составление стратегии продвижения</li>
              <li class="card__item">Контроль за реализацией проекта и KPI</li>
              <li class="card__item">Проверка работы команды</li>
            </ul>
          </div>
        </div>
        <div class="team__card">
          <img src="./../assets/a.svg" alt="" class="team__img">
          <div class="card__text-block">
            <p class="card__title">Аналитик</p>
            <ul class="card__list">
              <li class="card__item">Настройка аналитики и отслеживание результатов</li>
              <li class="card__item">Анализирует и выявление конкурентов</li>
              <li class="card__item">Анализ позиции клиента на рынке</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <img class="dots footer-dots-team" src="./../assets/dots.svg" alt="">
    <img class="figure parallax team-figure-desk-top figure_white" src="./../assets/figure-mobile.svg" alt="">
    <img class="figure parallax team-figure-desk-bottom figure_white" src="./../assets/figure-mobile.svg" alt="">
  </section>
</template>

<script>
export default {
  name: "Team",
};
</script>

<style lang="scss">
.team {
  background: url('./../assets/exp.png') center/auto no-repeat;
  background-size: auto 170%;
  padding-bottom: 83px;
  position: relative;
  
  .title-text {
    text-transform: uppercase;
  }
  
  .team-figure-desk-top {
    position: absolute;
    height: 250px;
    width: 211px;
    top: -67px;
    right: 36px;
  }

  .footer-dots-team {
    bottom: -440px;
    left: -690px;
  }

  .team-figure-desk-bottom {
    position: absolute;
    height: 115px;
    width: 97px;
    top: 195px;
    right: 36px;
  }

  &-container {
    margin-top: 60px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 40px;
  }

  &__img {
    width: 86px;
    height: 86px;
    position: relative;
    z-index: 7;
  }

  .card__title {
    font-size: 20px;
    font-weight: 900;
    margin: 27px 0;
  }

  .card__text-block {
    position: relative;
    z-index: 7;
  }

  .card__list {
    list-style: disc;
  }

  .card__item {
    width: 50%;
    margin: 10px 0 10px 17px;
  }
}

@media (max-width: 1024px) {
  .team-figure-desk-top {
    display: none;
  }
}

@media (max-width: 768px) {
  .team {
    padding-top: 0;

    .team-figure-desk-bottom {
      top: -56px;
      right: 6px;
    }

    .footer-dots-team {
      width: 500px;
      position: absolute;
      left: -204px;
      bottom: -171px;
    }

    .title-text {
      font-size: 34px;
    }

    &__card {
      display: flex;
    }

    .team-container {
      margin-top: 0;
      grid-template-columns: 1fr;
    }

    .card__title {
      font-weight: 700;
    }

    .card__text-block {
      margin-left: 30px;
    }

    .card__item {
      width: 100%;
    }
  }
}

</style>