<template>
  <form
      ref="form"
      @submit.prevent="send(this.$refs.form)"
      class="contacts__form"
      :class="isFormBlocked ? 'contacts__form_blocked' : null"
      method="post"
      action="/sender.php"
  >
    <img class="form-close" @click="closeModal" v-if="isModal" src="./../assets/close.svg" alt="">
    <fieldset v-if="sentStatus === 'wait' || sentStatus === 'success'" class="form-field">
      <legend class="title-text">{{ title }}</legend>
      <input
          class="form-input"
          type="text"
          name="name"
          placeholder="Ваше имя"
          v-model="name"
          required
      >
      <input
          class="form-input"
          type="email"
          name="email"
          placeholder="Email"
          v-model="email"
      >
      <input
          type="tel"
          name="phone"
          class="form-input"
          placeholder="Контактный телефон"
          minlength="11"
          v-model="phone"
          required
      >
      <input
          class="form-input"
          type="text"
          name="comments"
          v-model="comments"
          placeholder="Кратко опишите задачу"
      >
      <input
          class="submit"
          type="submit"
          name="submit"
          v-bind:value="value"
          required
      >
    </fieldset>
    <fieldset>
      <input
          v-for="(input, i) in utmList"
          :name="input.name"
          :value="input.value"
          class="testClass"
          :key="i"
          type="hidden"
      >
    </fieldset>
    <div class="contacts__form_success">
      <p v-if="sentStatus === 'error'" class="title-text">Заявка не отправлена.<br>Попробуйте ещё раз.</p>
    </div>
  </form>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import { mapFields } from 'vuex-map-fields';

export default {
  name: "Form",
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    isModal: {
      type: Boolean
    }
  },
  methods: {
    ...mapMutations({
      send: 'send',
      closeModal: 'closeModal',
      utmData: 'utmData'
    })
  },
  mounted() {
    this.utmData();
  },
  computed: {
    ...mapState({
      utmList: state => state.form.utmList,
      sentStatus: state => state.form.sentStatus,
      isFormBlocked: state => state.form.isFormBlocked
    }),
    ...mapFields([
        'name',
        'email',
        'phone',
        'comments',
    ])
  }
};
</script>

<style lang="scss">

.contacts__form {
  margin: 70px 0 70px;
  z-index: 3;
  max-width: 577px;
  width: 100%;

  fieldset {
    border: none;
  }

  legeng {
    font-size: 50px;
    font-weight: 900;
  }

  .form-input {
    border: 1px solid #C3C6CC;
    padding: 0 24px;
    width: 100%;
    height: 56px;
    border-radius: 10px;
    margin-bottom: 20px;

    &::placeholder {
      color: #C3C6CC;
      font-size: 16px;
    }
  }

  .submit {
    border-radius: 10px;
    background-color: #FF4E00;
    border: none;
    font-weight: 700;
    font-size: 14px;
    color: #fff;
    width: 180px;
    height: 50px;
    margin-top: 17px;
    cursor: pointer;
    transition: .4s all ease;

    &:hover {
      background-color: #00173f;
    }
  }
}

.contacts__form_blocked {
  .form-input {
    background-color: #eee;
    pointer-events: none;
  }

  .submit {
    background: #ff4e00a1;
    pointer-events: none;
    cursor: not-allowed;

    &:hover {
      background-color: #ff4e00a1;
    }
  }
}
</style>