<template>
  <router-link @click="backHandler" :to="link">
    <img class="back" src="./../assets/back.svg" alt="">
  </router-link>
</template>

<script>
export default {
  name: "Back",
  data() {
    return {
      link: ''
    };
  },
  methods: {
    backHandler() {
      return this.link = history.back();
    }
  }

};
</script>