<template>
  <Header />
  <router-view/>
</template>

<script>
import Header from "@/components/Header";
import {mapState} from "vuex";

export default {
  components: {
    Header
  },
  data() {
    return {
      windowsHeight: window.innerHeight,
      elementVisible: 150
    };
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.revealsAnimation();
      this.parallaxAnimation();
    },

    revealsAnimation() {
      const reveals = document.querySelectorAll('.reveal');

      reveals.forEach(element => {
        let elementTop = element.getBoundingClientRect().top;

        elementTop < (this.windowsHeight - this.elementVisible)
            ? element.classList.add('active')
            : element.classList.remove('active');
      });
    },
    parallaxAnimation() {
      const parallax = document.querySelectorAll(".parallax");

      let offset = window.scrollY;
      parallax.forEach((prllx, i) => {
        prllx.style.backgroundPositionY = (offset - prllx.offsetTop) * 0.8 + "px";

        if (prllx.className.includes('figure')) {
          prllx.style.transform = `translateY(${-(offset - prllx.offsetTop) * 0.4}px)`;
        }
      });
    }
  },
  computed: {
    ...mapState({
      modalIsOpened: state => state.stages.modalIsOpened
    })
  }
};

</script>

<style lang="scss">

html {
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

ul[class],
ol[class] {
  padding: 0;
}

label {
  display: block;
  margin: 10px 0 5px;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  font-size: inherit;
  margin: 0;
}

ul {
  list-style: none;
}

img {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
}

a {
  text-decoration: none;
}

html,
body {
  height: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  color: #00173F;
  min-width: 320px;
  font-size: 14px;
  line-height: 1.2;
  font-family: 'Montserrat', sans-serif;
  transition: .3s all ease;
  background-color: #FAFAFA;
}

section,
header {
  max-width: 1920px;
  margin: 0 auto;
}

.container {
  width: 100%;
  max-width: 1295px;
  margin: 0 auto;
  padding: 0 45px;
}

.darken {
  background-color: #00173F;
  transition: .4s all ease;

  .example__logo.bi-group {
    filter: invert(99%) sepia(176%) saturate(10%) hue-rotate(0deg) brightness(145%) contrast(100%)
  }

  .example__line-text,
  .example__line-title,
  .request-frm .title-text {
    color: #fff !important;
  }

  .header__nav {
    background-color: #00173f;
  }

  .kaspi {
    content: url("./assets/kaspi-black.svg");
  }

  .header__logo {
    content: url('./assets/logo-dark.svg');
    transition: .3s all ease;
  }

  .request:hover, .portfolio__link:hover {
    background-color: #fff;
    color: #000;
  }

  .kmf {
    content: url('./assets/kmf-black.svg');
  }

  .slider {
    border: 1px solid #fff !important;
  }

  .burger div {
    background-color: #fff;
  }

  .header__menu_active {
    background-color: #00173F !important;
  }

  .header__menu-link {
    color: #fff !important;
  }

  .footer__logo {
    content: url('./assets/footer-logo-dark.svg');
  }

  .title,
  .header-main__block-text,
  .header-main__text,
  .footer__title,
  .footer__link,
  .stages__text,
  .footer__address,
  .footer__contact,
  .footer__copyright,
  .portfolio__name,
  .contacts .title-text,
  .contacts__info-link,
  .contacts__address,
  .contacts__form .title-text,
  .result__title_medium {
    transition: .3s all ease;
    color: #fff;
  }

  .header-main .title {
    color: #00173f;
  }

  .result,
  .stages,
  .benefits,
  .portfolio,
  .contacts,
  .header-main_home,
  .request-frm {
    background: url("./assets/main-dark.png") !important;
  }

  .request-frm .title-text {
    color: #fff;
  }

  .benefits {
    .experience__item {
      filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(79deg) brightness(102%) contrast(376%) !important;
    }
  }

  .footer__contact::before, .footer__phones::before, .footer__address::before {
    filter: invert(45%) sepia(55%) saturate(6016%) hue-rotate(1deg) brightness(101%) contrast(109%);
  }

  .header-main_home {
    .title {
      color: #fff !important;
    }

    .header-main__block-text {
      color: #fff !important;
    }

    .header-main__text {
      color: #fff !important;
    }
  }

  .submit:hover {
    background-color: #ff4e00 !important;
  }
}

.figure, .dots {
  z-index: 1;
  position: absolute;
  pointer-events: none;
}

.figure_white {
  filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(79deg) brightness(102%) contrast(376%);
}

.case_small {
  right: 40px;
  bottom: 80px;
}

.title {
  color: #00173F;
  font-size: 90px;
  font-weight: 900;
  text-transform: uppercase;
  position: relative;
  z-index: 10;

  span {
    color: #FF4E00;
  }
}

@media (max-width: 1024px) {
  .title {
    font-size: 60px;
  }

  .case_small {
    bottom: 30px;
    right: 70px;
  }
}

@media (max-width: 768px) {
  .darken .header__logo {
    content: url('./assets/header-logo-dark-mobile.svg');
  }

  .figure.parallax {
    transform: translateY(0) !important;
  }

  .figure_services.parallax {
    transform: translateY(0) !important;
  }

  .title-text_success {
    font-size: 24px !important;
  }

  .dots {
    width: 300px;
    height: 300px;
  }

  .title {
    font-size: 34px !important;
  }
}
</style>
