<template>
  <section id="frm" class="request-frm">
    <div class="container">
      <div class="header-main__form">
        <Form
            title="заявка на сотрудничество"
            value="Оставить заявку"
        />
      </div>
    </div>
    <img class="figure parallax figure-request-desktop" src="./../assets/figure.svg" alt="">
    <img class="figure parallax figure-request-desktop_small" src="./../assets/figure.svg" alt="">
  </section>
</template>

<script>
import Form from "@/components/Form";

export default {
  name: "RequestForm",
  components: {
    Form
  }
};
</script>

<style lang="scss">
.request-frm {
  background: url('./../assets/main.png') center/cover no-repeat;
  width: 100%;
  max-width: inherit;
  position: relative;

  .figure-request-desktop {
    width: 360px;
    height: 420px;
    position: absolute;
    top: 140px;
    right: 128px;

    &_small {
      width: 76px;
      height: 90px;
      position: absolute;
      top: 40px;
      right: 50px;
    }
  }

  .title-text {
    font-size: 50px;
    color: #00173f !important;
  }

  .contacts__form {
    margin-top: 0;

    .title-text {
      text-transform: uppercase;
      margin-bottom: 84px;
    }

    input {
      width: 600px !important;
      z-index: 5;
      position: relative;
    }

    input[type=submit] {
      width: 180px !important;
    }
    
    input[name=comments] {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .request-frm {
    padding: 30px 0;

    .figure-request-desktop {
      filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(79deg) brightness(102%) contrast(376%);
      width: 109px;
      height: 92px;
      top: -59px;
      right: 27%;
    }

    .figure-request-desktop_small {
      width: 37px;
      height: auto;
      position: absolute;
      top: 31px;
      right: 63px;
    }

    .contacts__form {
      width: 100% !important;

      .title-text {
        font-size: 24px;
        margin-bottom: 0;
        padding: 5px 0 30px !important;
      }

      input,
      fieldset,
      .contacts__form {
        width: 100% !important;
      }

      fieldset {
        padding: 0;
      }

      .contacts__form {
        margin-top: -10px;
      }
    }
  }
}
</style>