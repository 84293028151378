<template>
  <HeaderMain/>
  <Benefits/>
  <Team/>
  <RequestForm/>
  <Experience/>
  <Stages/>
  <Services/>
  <Portfolio/>
  <Clients/>
  <Contacts/>
  <Footer/>
</template>

<script>
import Header from '@/components/Header.vue';
import Experience from "@/components/Experience";
import Stages from "@/components/Stages";
import Services from "@/components/Services";
import Portfolio from "@/components/Portfolio";
import Contacts from "@/components/Contacts";
import Footer from "@/components/Footer";
import Clients from "@/components/Clients";
import HeaderMain from "@/components/HeaderMain";
import Benefits from "@/components/Benefits";
import Team from "@/components/Team";
import RequestForm from "@/components/RequestForm";

export default {
  name: 'Performance',
  components: {
    RequestForm,
    Team,
    Benefits,
    HeaderMain,
    Footer,
    Contacts,
    Portfolio,
    Experience,
    Header,
    Stages,
    Services,
    Clients
  },
  mounted() {
    window.onhashchange = function() {
      setTimeout(() => {
        let element = document.getElementById('portfolio-anchor');
        let top = element.offsetTop;

        window.scrollTo(0, top - 40);
      }, 100)
    }
  },
};
</script>
