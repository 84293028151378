<template>
  <section class="example parallax">
    <div class="container">
      <img src="./../assets/figure-mobile.svg" alt="" class="figure parallax figure-example-header">
      <div id="example-name" class="example__name">
        <h1 class="title">KMF</h1>
        <img class="example__logo kmf" alt="" src="./../assets/kmf.svg">
      </div>
      <div class="example__line example__line-nsk">
        <div class="example__line-title">
          Задачи
        </div>
        <div class="example__line-text">
          Увеличение количества установок приложения микрофинансовой организации KMF
        </div>
      </div>
      <div class="example__line example__line-nsk">
        <div class="example__line-title">
          Использовали:
        </div>
        <div class="example__line-text">
          Инструмент Google КМС
        </div>
      </div>
    </div>
    <img class="figure parallax header-figure_bottom-small case_small" src="./../assets/figure.svg" alt="">
    <img src="./../assets/dots.svg" alt="" class="dots case-dots-header">
  </section>
  <Back/>
  <section class="realization smooth parallax">
    <div ref="reveal" class="container reveal">
      <h1 class="title">Реализация</h1>
      <p class="realization__text">Данная Performance кампания была направлена на получение установок приложения.</p>
      <div class="realization__cards kmf-cards">
        <div class="realization__card">
          <div class="realization__card-number">1</div>
          <div class="realization__card-title">Анализ рынка</div>
          <div class="realization__card-text">Был произведен комплексный анализ рынка и запросов в поисковиках по тематике микрокредитов.</div>
        </div>
        <div class="realization__card">
          <div class="realization__card-number">2</div>
          <div class="realization__card-title">Стратегия</div>
          <div class="realization__card-text">Сегментирование предложений по группам, разработка подходящих объявлений под каждую группу.</div>
        </div>
        <div class="realization__card">
          <div class="realization__card-number">3</div>
          <div class="realization__card-title">Запуск кампании </div>
          <div class="realization__card-text">Запуск кампании в рамках тестирования,
            с последующей оптимизацией после получения первых результатов.</div>
        </div>
        <div class="realization__card">
          <div class="realization__card-number">4</div>
          <div class="realization__card-title">Анализ и Оптимизация</div>
          <div class="realization__card-text">Анализ отработки стратегии, анализ эффективности групп объявлений и объявлений.</div>
        </div>
      </div>
    </div>
    <img class="figure_result figure_white" src="./../assets/figure.svg" alt="">
    <img class="dots dots-cases_footer" src="./../assets/dots.svg" alt="">
  </section>
  <section class="result parallax result_kmf">
    <img class="dots dots-cases_footer" src="./../assets/dots.svg" alt="">
    <img class="figure_creatives-big figure_white" src="./../assets/figure.svg" alt="">
    <img class="figure_creatives figure_white" src="./../assets/figure.svg" alt="">
    <img class="dots dots-cases_footer-result" src="./../assets/dots.svg" alt="">
    <div ref="container" class="container reveal">
      <h1 class="title">Результат</h1>
      <p class="result__text"></p>
      <div class="header-main__stats kmf-stats">
        <div class="header-main__block">
          <span class="header-main__block-number">29 900</span>
          <p class="header-main__block-text">кликов</p>
        </div>
        <div class="header-main__block">
          <span class="header-main__block-number">7240</span>
          <p class="header-main__block-text">установок приложения стоимостью $0,37</p>
        </div>
      </div>
    </div>
  </section>
  <section class="creatives smooth kmf-creatives parallax">
    <img class="dots dots-cases_header" src="./../assets/dots.svg" alt="">
    <div ref="creatives" class="container reveal">
      <h1 class="title">Креативы</h1>
      <div class="creatives__cards">
        <div class="creatives__card creatives__card_kmf">
          <p>Google - КМС (Контекстно медийная сеть)</p>
          <img class="creatives__card" src="./../assets/kmf-1.png" alt="">
        </div>
        <div class="creatives__card creatives__card_kmf">
          <p>Youtube - Рекомендации</p>
          <img class="creatives__card" src="./../assets/kmf2.png" alt=""></div>
        <div class="creatives__card creatives__card_kmf">
          <p>Google - Поиск</p>
          <img class="creatives__card" src="./../assets/kmf-3.png" alt=""></div>
      </div>
    </div>
  </section>
  <Footer/>
</template>

<script>
import Back from "@/components/Back";
import Footer from "@/components/Footer";
import Header from "@/components/Header";

export default {
  components: {
    Header,
    Back,
    Footer
  },
  name: "KmfView",
};
</script>
