<template>
  <section id="experience" class="experience smooth parallax">
    <img class="dots header-dots" src="./../assets/dots.svg" alt="">
    <div class="container reveal parallax">
      <h3 class="title-text experience-title-text">Мы имеем огромный опыт работы, сертификаты и
        партнёрства с
        такими инструментами как:</h3>
      <div class="experience__instruments">
        <img class="experience__item" src="./../assets/instruments/google.svg" alt="Google">
        <img class="experience__item" src="./../assets/instruments/yandex.svg" alt="Yandex">
        <img class="experience__item" src="./../assets/instruments/facebook.svg" alt="facebook">
        <img class="experience__item" src="./../assets/instruments/video360.svg" alt="Display & Video 360">
        <img class="experience__item" src="./../assets/instruments/my-target.svg" alt="My-Target">
        <img class="experience__item" src="./../assets/instruments/vk.svg" alt="VK">
        <img class="experience__item" src="./../assets/instruments/tt.svg" alt="TikTok">
        <img class="experience__item" src="./../assets/instruments/admixer.svg" alt="ADMIXER">
      </div>
    </div>
    <img id="experience-dots" class="dots footer-dots" src="./../assets/dots.svg" alt="">
    <img class="figure_experience-white figure parallax" src="./../assets/figure.svg" alt="" style="display: block">
    <img class="figure_experience-small figure parallax header-figure_bottom-small" src="./../assets/figure.svg" alt=""
         style="display: block">
  </section>
</template>

<script>
export default {
  name: "Experience",
};
</script>

<style lang="scss">
.experience {
  background: url("./../assets/exp.png") center/cover no-repeat !important;
  position: relative;

  .title-text {
    color: #fff !important;
  }

  .container {
    z-index: 4;
  }

  &__instruments {
    position: relative;
    margin-top: 116px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    &::before {
      content: '';
      background-color: #fff !important;
      width: 100%;
      height: 2px;
      position: absolute;
      top: -37px;
      transform: translateX(-1000%);
    }

    &_expanded {

      &::before {
        animation: 1s expandLine ease forwards;
      }

      @keyframes expandLine {
        0% {
          transform: translateX(-1000%);
        }
        100% {
          transform: translateX(0);
        }
      }
    }
  }

  &__item {
    margin-bottom: 40px;
    z-index: 3;
  }
}

.figure_result-big {
  width: 370px;
  height: 318px;
  position: absolute;
  left: -196px;
  top: 200px;
}

.figure_experience-small {
  bottom: -200px;
  right: 550px;
  position: absolute;
  z-index: 3;
}

.dots-cases_footer {
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 0 0 -400px -600px;
}

.dots-cases_header {
  position: absolute;
  right: 0;
  top: 0;
  margin: -400px -600px 0 0;
}

.figure_experience-white {
  position: absolute;
  right: 329px;
  bottom: -180px;
  width: 260px;
  height: 222px;
  z-index: 2;
}

.figure {
  z-index: 2 !important;
}

#experience-dots {
  @media (max-width: 768px) {
    bottom: -154px !important;
    z-index: 2;
    top: auto;
  }
}

.figure_result {
  position: absolute;
  bottom: 4px;
  left: 200px;
  width: 100px;
  height: 55px;
}

.figure_creatives-big {
  width: 180px;
  height: 220px;
  position: absolute;
  left: 50px;
  bottom: 100px;
}

.figure_creatives {
  position: absolute;
  width: 50px;
  height: 65px;
  left: 50px;
  bottom: 270px;
}

.title-text {
  font-weight: 900;
  font-size: 56px;
  color: #fff;
  position: relative;
  z-index: 20;
}

.smooth {
  border-radius: 200px 0;
  color: #fff !important;

  .title {
    color: #fff !important;
    z-index: 3 !important;
  }
}

.experience {
  .figure_experience-white.parallax {
    right: -119px;
  }
}

section.smooth {

  .header-dots {
    width: 1000px;
    position: absolute;
    top: -448px;
    right: -587px;
    z-index: 3;
  }

  .footer-dots {
    left: -593px;
    top: 43px;
    z-index: 2;
    width: 1000px;

    &_services {
      left: -593px;
      top: 361px;
    }

    &_clients {
      left: -593px;
      top: auto !important;
      bottom: -369px;
      z-index: 2 !important;
    }

    &_case {
      top: 488px;
    }
  }
}

section {
  padding: 64px 0 22px;
}

section.smooth {
  //background-size: cover !important;
}

.partners__cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  column-gap: 250px;

}

.partners_card {
  width: 100%;
}

@media (max-width: 1850px) {
  .figure_experience-white {
    display: none;
  }
}


@media (max-width: 1600px) {
  .figure_result-big {
    display: none;
  }

  .experience {
    .figure_experience-white.parallax {
      right: 300px;
      transform: translateY(0) !important;
    }
  }
}

@media (max-width: 1280px) {
  .figure_experience-small {
    bottom: -200px;
    right: 250px;
  }

  .figure_experience-white {
    right: 29px;
  }

  .experience__instruments {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .experience__item {

    &:nth-child(1) {
      margin-right: 162px;
    }

    &:nth-child(2) {
      margin-right: 119px;
    }

    &:nth-child(4) {
      margin-right: 25px;
    }

    &:nth-child(7) {
      margin-right: 160px;
    }
  }

  section.smooth {

    .footer-dots {

      &_services {
        left: -498px;
        top: 778px;
      }

      &_case {
        top: 628px;
      }
    }
  }
}

@media (max-width: 1080px) {

  .experience {
    padding-bottom: 100px !important;

    .partners__cards {
      width: 100% !important;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 50px !important;
    }

    .partners_card {
      width: 300px;
      margin-right: 0 !important;

      &:nth-child(4) {
        margin-left: -20px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .experience__instruments {
    grid-template-columns: 1fr 1fr;
    justify-items: stretch;
  }

  section.smooth {

    .header-dots {
      width: 1000px;
      position: absolute;
      top: -448px;
      right: -587px;
      z-index: 3;
    }

    .footer-dots {
      left: -329px;
      top: 409px;
      z-index: 2;
      width: 500px;

      &_services {
        left: -207px;
        top: auto !important;
        bottom: -170px;
      }

      &_clients {
        top: auto !important;
        bottom: -249px;
      }

      &_case {
        top: 1228px;
      }
    }
  }

  .experience__instruments {
    width: 600px;
    column-gap: 80px;
    row-gap: 70px;
    padding-bottom: 60px;
  }

  .partners_card:nth-child(4) {
    margin-left: -21px !important;
  }

  .experience__item {
    margin: 0 0 0 0 !important;

    &:nth-child(3) {
      margin-right: -23px !important;
    }

    &:nth-child(5) {
      margin-right: -23px !important;
    }
  }
}

@media (max-width: 768px) {
  .circle {
    content: url("./../assets/main-mobile.png");
  }

  .figure_creatives-big {
    width: 97px;
    height: 115px;
    left: auto;
    right: 20px;
    bottom: auto;
    top: -5px;
  }

  .figure_creatives {
    position: absolute;
    width: 30px;
    height: 44px;
    left: auto;
    bottom: auto;
    right: 30px;
    top: 45px;
  }

  .figure_result {
    position: absolute;
    bottom: -4px;
    left: 50px;
    width: 70px;
    height: 86px;
    display: none;
  }

  .dots-cases_footer {
    margin: 0 0 -150px -150px;
  }

  .dots-cases_header {
    margin: -150px -150px 0 0;
  }

  #partners {
    padding-bottom: 50px !important;
  }

  .smooth {
    border-radius: 60px 0;
  }

  .title, .title-text {
    padding: 40px 0 !important;
  }

  section.smooth .header-dots {
    width: 602px;
    position: absolute;
    top: -120px;
    right: -103px;
    z-index: 4;
  }

  section.smooth .footer-dots {
    left: -182px !important;
    bottom: -162px !important;
    top: auto;

    &_clients {
      left: -190px;
      bottom: -146px;
    }

    &_case {
      top: 1328px;
      left: -177px;
    }
  }

  .experience {

    &__item {
      width: 80%;
    }

    .experience__instruments {
      row-gap: 32px;
    }

    .title-text {
      margin-top: 0;
    }

    &__instruments {
      margin-top: 36px;
      padding-bottom: 0;

      &::before {
        top: -43px;
      }
    }
  }

  .title-text {
    font-size: 24px;
  }

  .figure_experience-white {
    width: 94px;
    height: 114px;
    bottom: -169px !important;
    right: 14px !important;
  }

  .figure_experience-small {
    right: 100px;
    bottom: -89px !important;
  }
}

@media (max-width: 585px) {
  //section {
  //  background-size: 587% !important;
  //}
  .experience {

    &__instruments {
      column-gap: 20px;
    }

    &__item {
      width: 77% !important;
    }

    .partners_card {
      margin-bottom: 20px !important;


      &:nth-child(4) {
        margin-left: -10px !important;
      }
    }
  }

  .experience__instruments {
    width: 400px;
  }

  .experience__item {
    width: 120px;

    &:nth-child(1) {
      margin-right: -30px !important;
    }

    &:nth-child(5) {
      margin-right: -25px !important;
    }

    &:nth-child(7) {
      margin-right: -25px !important;
    }
  }
}

@media (max-width: 485px) {
  .footer-dots_case {
    top: 1655px !important;
    left: -150px !important;
  }
}


@media (max-width: 375px) {

  .experience__instruments {
    width: 100%;
  }
}
</style>