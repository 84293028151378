<template>
  <section class="example">
    <div class="container">
      <img src="./../assets/figure-mobile.svg" alt="" class="figure parallax figure-example-header">
      <div id="example-name" class="example__name">
        <h1 class="title">BI Group</h1>
        <img class="example__logo bi-group" alt="" src="./../assets/bi-group.png">
      </div>
      <div class="example__line">
        <div class="example__line-title">
          Задачи
        </div>
        <div class="example__line-text">
          Привлечение новых клиентов для продажи квартир не более 500 руб за клиента.
        </div>
      </div>
      <div class="example__line">
        <div class="example__line-title">
          Использовали:
        </div>
        <div class="example__line-text">
          Инструмент MyTarget
          <span>Формат рекламы - мультиформат, Lead-форма</span>
        </div>
      </div>
    </div>
    <!--    <img class="figure header-figure_bottom figure_white" src="/assets/figure.svg" alt="">-->
    <img class="figure parallax header-figure_bottom-small case_small" src="./../assets/figure.svg" alt="">
    <img src="./../assets/dots.svg" alt="" class="dots case-dots-header">
  </section>
  <Back/>
  <section class="realization smooth bi-group__realization parallax">
    <div ref="reveal" class="container reveal">
      <h1 class="title">Реализация</h1>
      <p class="realization__text bi-group__text">Данная Performance кампания была направлена на получение лидов, тестирование аудиторий и креативов.</p>
      <div class="realization__cards bi-group__cards">
        <div class="realization__card">
          <div class="realization__card-number">1</div>
          <div class="realization__card-title">Запуск Мультиформата</div>
          <div class="realization__card-text">Запустили все аудитории которые могут быть целевыми для бизнеса клиента.</div>
        </div>
        <div class="realization__card">
          <div class="realization__card-number">2</div>
          <div class="realization__card-title">Оптимизация</div>
          <div class="realization__card-text">После сбора достаточного количества трафика анализ результатов аудиторий и исключение аудиторий с ценой за лид выше 500 руб</div>
        </div>
        <div class="realization__card">
          <div class="realization__card-number">3</div>
          <div class="realization__card-title">Анализ и Оптимизация</div>
          <div class="realization__card-text">Повтор предыдущего действия с другими форматами рекламы.</div>
        </div>
      </div>
    </div>
    <img class="figure_result figure parallax figure_white" src="./../assets/figure.svg" alt="">
    <img class="dots dots-cases_footer" src="./../assets/dots.svg" alt="">
  </section>
  <section class="result result_bi-group parallax">
    <img class="dots dots-cases_footer" src="./../assets/dots.svg" alt="">
    <img class="figure_creatives-big parallax figure_white" src="./../assets/figure.svg" alt="">
    <img class="figure_creatives parallax figure_white" src="./../assets/figure.svg" alt="">
    <img class="dots dots-cases_footer-result" src="./../assets/dots.svg" alt="">
    <div ref="container" class="container reveal">
      <h1 class="title">Результат</h1>
      <p class="result__text">
        В итоге у нас осталось 2 формата: Мультиформат и Lead Ads, которые в сумме
        с аудиториями которые мы вычислили из теста дали нам
      </p>
      <p class="result__text_big">общую цену за лид<span>368₽</span></p>
    </div>
  </section>
  <section class="creatives smooth parallax">
    <img class="dots dots-cases_header" src="./../assets/dots.svg" alt="">
    <div ref="creatives" class="container reveal">
      <h1 class="title">Креативы</h1>
      <p class="creatives__text">MyTarget -<br>Мультиформат</p>
      <div class="creatives__cards">
        <img class="creatives__card" src="./../assets/creative-1.png" alt="">
        <img class="creatives__card" src="./../assets/creative-2.png" alt="">
        <img class="creatives__card" src="./../assets/creative-3.png" alt="">
      </div>
    </div>
  </section>
  <Footer/>
</template>

<script>
import Back from "@/components/Back";
import Footer from "@/components/Footer";
import Header from "@/components/Header";

export default {
  components: {
    Back,
    Footer,
    Header
  },
  name: "BiGroupView",
};
</script>