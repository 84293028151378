<template>
  <div class="stages__steps stages_expanded">
    <div v-for="item in stage" class="stages__step">
      <div class="stages__img">
        <img class="stages__img-pic" :src="item.img" :alt="item.title">
        <img
             class="stages__img-arrow arrow_expanded"
             src="./../assets/arrow.svg" alt=""
        >
      </div>
      <div class="stages__text text_expanded">
        <h4 class="stages__title">{{ item.title }}</h4>
        <p class="stages__description">
          {{ item.text }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StagesStep",
  data() {
    return {
      stage: [{
        id: 1,
        img: "/img/strategy.svg",
        title: 'Стратегия',
        text: 'Погружаемся в бизнес клиента и разрабатываем стратегию продвижения'
      },
        {
          id: 2,
          img: '/img/team.svg',
          title: 'Команда',
          text: 'Собираем команду специалистов для каждого клиента'
        },
        {
          id: 3,
          img: '/img/start.svg',
          title: 'Запуск',
          text: 'Настройка и запуск рекламных кампаний, тестирование различных гипотез и проведение оптимизации'
        },
        {
          id: 4,
          img: '/img/report.svg',
          title: 'Отчетность',
          text: 'Предоставляем регулярные отчеты о результатах работы'
        }
      ]
    };
  },
};
</script>
